import React from 'react';
import styles from './styles.module.css';
import { User } from '../../types/user';
import SelectDeck from '../../components/SelectDeck';

interface HomeProps {
  user?: User;
}

class Home extends React.PureComponent<HomeProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.Wrapper}>
        <h1 className={styles.Title}>Welcome to Battle Beasts</h1>
        <p className={styles.Subtitle}>A turn based card game</p>

        {this.props.user != null ? (
          <div className={styles.BoxWrapper}>
            <SelectDeck user={this.props.user} />
          </div>
        ) : (
          <h3>You need to be logged in to play a match</h3>
        )}
      </div>
    );
  }
}

export default Home;
