import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import styles from './styles.module.css';
import Main from '../../components/Main';
import Container from '../../components/Container';
import AboutCard from '../../components/AboutCard';
import PlayerImage from '../../assets/images/about/player.png';
import AnimalCardImage from '../../assets/images/about/animal.png';
import EquipmentCardImage from '../../assets/images/about/equipment.png';
import AttackIcon from '../../assets/images/about/attack-icon.png';
import DefenseIcon from '../../assets/images/about/defense-icon.png';
import ActionIcon from '../../assets/images/about/action-icon.png';
import GameRoundImage1 from '../../assets/images/about/gameround-1.png';
import GameRoundImage2 from '../../assets/images/about/gameround-2.png';
import GameRoundImage3 from '../../assets/images/about/gameround-3.png';
import GameRoundImage4 from '../../assets/images/about/gameround-4.png';
import GameRoundImage5 from '../../assets/images/about/gameround-5.png';

class About extends React.PureComponent {
  public render(): React.ReactNode {
    const swiperParams = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      simulateTouch: false,
    };

    return (
      <Main>
        <Container>
          <h1 className={styles.Title}>About the game</h1>
          {/* Basics */}
          <AboutCard>
            <div>
              <h2>Basics</h2>
              <p>
                Battle Beasts is a turn-based card game where two players with a certain amount of <b>health</b> and{' '}
                <b>action points</b> battle each other until one party has no more <b>health points</b> left. The card
                game itself consists of <b>animal cards</b> which fight each other or attack the opponent directly and{' '}
                <b>equipment cards</b> which improve animals permanently. Every card costs a certain amount of{' '}
                <b>action points</b> so that you have a limitation of tactical moves for any turn you make.
              </p>
              <p>We will now explain the points, cards, and stats in detail.</p>
            </div>
          </AboutCard>

          {/* Player */}
          <AboutCard>
            <div>
              <img src={PlayerImage} alt="Player" className={styles.imageLeft} height={250} />
              <h2>Player</h2>
              <p>
                During the game, you will see this icon on the left side of your screen. It is a default picture with
                your name in it and shows both of your player stats: the <b>health</b> and the <b>action points</b>.
              </p>
              <p>
                The <b>health points</b> show your health in general, they will be decreased by your opponent’s attacks
                and as soon as they reach zero you lose the game.
              </p>
              <p>
                The <b>action points</b> show how many cards you still can play. Every time you put a card on the board
                the action points from the card will be subtracted from your action points. As soon as they reach zero
                it is not possible to put another card on the table. Your action points will be filled up by the
                beginning of every round.
              </p>
            </div>
          </AboutCard>

          {/* Animal cards */}
          <AboutCard>
            <div>
              <img src={AnimalCardImage} alt="Animal card" className={styles.imageRight} height={300} />
              <h2>Animal cards</h2>
              <p>
                The name of the card is written on top and underneath, you will find a picture of the animal and a short
                description of the animal.
              </p>
              <p>
                The species of a card is shown in the top left corner of the card and is also written on the bottom
                left. Until now the species of the card has no influence on the gameplay.
              </p>
              <p> The most important information are the three stats that are written on the bottom right.</p>
              <ul>
                <li>
                  <img src={AttackIcon} alt="Attack icon" height={25} /> The attack value: Shows how strong the card is
                  in attack mode.
                </li>
                <li>
                  <img src={DefenseIcon} alt="Defense icon" height={25} /> The defense value: Shows how strong the card
                  is in defense mode.
                </li>
                <li>
                  <img src={ActionIcon} alt="Action icon" height={25} /> The action points value: Shows how much the
                  card costs when you put in on the board (the value that will be subtracted from your action points).
                </li>
              </ul>
            </div>
          </AboutCard>

          {/* Equipment cards */}
          <AboutCard>
            <div>
              <img src={EquipmentCardImage} alt="Equipment card" className={styles.imageLeft} height={300} />
              <h2>Equipment cards</h2>
              <p>
                The name of the equipment card is written on top, underneath is a picture that supports the effect and a
                little description which states for which type of animal it can be used. The equipment card can be
                recognized on the top left of the card, it is marked with a plus and it is written on the bottom left.
              </p>
              <p>
                The equipment card has the same three stats on the bottom right of the card as an animal card. The
                action points will be subtracted from your action points in the same way as it would be an animal card.
                The attack and defense value of an equipment card will be added to the attack and defense value of the
                animal card on which the equipment card was assigned.
              </p>
            </div>
          </AboutCard>

          {/* Game rounds */}
          <AboutCard>
            <div className={styles.GameRoundsCard}>
              <h2>Game rounds</h2>
              <p>
                At the beginning of a round, your action points are filled up again and you get one new card. You start
                with the cast phase and you are able to put cards on the board.
              </p>
              <p>
                Animal and equipment cards can be put on the board and the action points of the cards will be subtracted
                from your action points. The mode in which the animal card is put on the table will determine how strong
                the card is. If you play it in attack mode, the attack value will be relevant. If you play it in defense
                mode, the defense value will be relevant. You can play as many cards as long as your action points are
                sufficient.
              </p>
              <p>After playing the cards that you wanted, you can go to the next phase: the attack phase.</p>
              <p>
                In the attack phase, you have the possibility to attack either your opponent or his animals, but some
                important rules must be paid attention to.
                <br />
                Click on the arrow buttons below the image to see all <b>5 rules</b> with detailed explanation.
              </p>
              <div className={styles.SwiperWrapper}>
                <Swiper {...swiperParams}>
                  <div className={styles.Slide}>
                    <img src={GameRoundImage1} alt="" />
                    <p>
                      <b>1.</b> Only animals in the attack mode can attack other animals.
                    </p>
                  </div>
                  <div className={styles.Slide}>
                    <img src={GameRoundImage2} alt="" />
                    <p>
                      <b>2.</b> Animals can only attack once every round and will be greyed out after an attack.
                      <br />
                      If an animal is defeated, it disappears from the board.
                    </p>
                  </div>
                  <div className={styles.Slide}>
                    <img src={GameRoundImage3} alt="" />
                    <p>
                      <b>3.</b> The attack value of your animal needs to be higher than the attack or defense value of
                      the targeted animal. Cards which attack or defense value is higher than your attack value will be
                      greyed out and you won&apos;t be able to attack them.
                    </p>
                  </div>
                  <div className={styles.Slide}>
                    <img src={GameRoundImage4} alt="" />
                    <p>
                      <b>4.</b> If an animal attacks another animal that is also in the attack mode, the difference
                      between your animal and the opponent’s animal attack points will be subtracted from his health
                      points.
                    </p>
                  </div>
                  <div className={`${styles.Slide} ${styles.LastSlide}`}>
                    <img src={GameRoundImage5} alt="" />
                    <p>
                      <b>5.</b> You can only attack your opponent directly when he has no animals on the board or when
                      all his animals on the board are in attack mode.
                    </p>
                  </div>
                </Swiper>
              </div>
              <p>
                If you have finished all your attacks or no attack is possible anymore, you can finish your round by
                pressing the &quot;End turn&quot; button to start your opponent’s turn.
              </p>
              <p>
                <b>Differences in the first round:</b>
              </p>
              <p>
                In the first round, both players get five cards on their hand to start. The player who starts can put
                cards on the board as usual but is not able to attack because the opponent hasn’t had the opportunity to
                build up a defense. As soon as the first turn is over, the opponent can put cards on the table and
                attack as usual.
              </p>
            </div>
          </AboutCard>

          {/* End */}
          <AboutCard>
            <div>
              <h2>End</h2>
              <p>
                As soon as a player&apos;s health reaches zero, the game is over and the winner is declared. Both
                players will receive a card as a reward.
              </p>
            </div>
          </AboutCard>
        </Container>
      </Main>
    );
  }
}

export default About;
