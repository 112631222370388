import React from 'react';
import { Deck } from '../../types/deck';

export default {
  deckPreview: {
    active: (decks: Deck[]): boolean => decks[0].name !== '',
    content: (): React.ReactNode => {
      return <div>Select your created deck</div>;
    },
  },
  manageDeck: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return (
        <div>
          Welcome to <b>BattleBeasts</b>! Let&apos;s start by creating your own<b> deck</b>
        </div>
      );
    },
  },
  playMode: {
    active: (decks: Deck[]): boolean => decks.length > 0,
    content: (): React.ReactNode => {
      return <div>You&apos;re all set and ready to play</div>;
    },
  },
  createDeck: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return <div>Click here to create a new deck</div>;
    },
  },
  addCard: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return (
        <div>
          These are your starting cards <br />
          New unlocked cards will be stored here <br />
          Click on a card to add it to your deck
        </div>
      );
    },
  },
  completeDeck: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return (
        <div>
          Add more cards to your deck <br />
          A deck always consists of 20 cards <br />
          You can <b>remove</b> a card if you click on it
        </div>
      );
    },
  },
  saveDeck: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return (
        <div>
          Don&apos;t forget to save the deck <br />
          You can always edit the deck later on
        </div>
      );
    },
  },
  nameDeck: {
    active: (decks: Deck[]): boolean => decks.length < 1,
    content: (): React.ReactNode => {
      return <div>Give your deck a cool name</div>;
    },
  },
};
