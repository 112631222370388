import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

interface PlayerAvatarProps {
  username: string;
  avatar: string;
  health: number;
  actionPoints: number;
  canAttack?: boolean;
  activityAttack?: boolean;
  onAttack?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
}

class PlayerAvatar extends React.PureComponent<PlayerAvatarProps> {
  public render(): React.ReactNode {
    return (
      <div
        className={classNames(
          styles.Wrapper,
          {
            [styles.CanAttack]: this.props.canAttack,
            [styles.ActivityAttack]: this.props.activityAttack,
          },
          this.props.className,
        )}
        onClick={(): void => {
          if (this.props.canAttack && this.props.onAttack) {
            this.props.onAttack();
          }
        }}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <img className={styles.Avatar} src={this.props.avatar} alt={this.props.username} />
        <div className={styles.Username}>{this.props.username}</div>
        <div className={styles.Health} style={{ '--health-width': `${100 / 30 * this.props.health}%` } as any /* eslint-disable-line */}>
          <span>{this.props.health} Health</span>
        </div>
        <div className={styles.ActionPoints} style={{ '--action-points-width': `${100 / 10 * this.props.actionPoints}%` } as any /* eslint-disable-line */}>
          <span>{this.props.actionPoints} Action Points</span>
        </div>
      </div>
    );
  }
}

export default PlayerAvatar;
