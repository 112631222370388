import React from 'react';
import styles from './styles.module.css';

class Main extends React.PureComponent {
  public render(): React.ReactNode {
    return <main className={styles.Main}>{this.props.children}</main>;
  }
}

export default Main;
