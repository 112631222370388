import React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './styles.module.css';
import { Card } from '../../types/card';
import CardComponent from '../CardComponent';
import PlayerAvatar from '../PlayerAvatar';
import OpponentAvatarImage from '../../assets/images/opponent-avatar.png';
import PlayerAvatarImage from '../../assets/images/player-avatar.png';
import Tutorial from '../Tutorial';
import { GameUpdate } from '../../types/gameUpdate';

interface GameBoardProps {
  playerName: string;
  playerCards: Card[];
  playerHealthPoints: number;
  playerActionPoints: number;
  opponent: string;
  opponentCards: Card[];
  opponentHealthPoints: number;
  opponentActionPoints: number;
  turn: string;
  nextTurnButton: (numAttacked: number) => React.ReactNode;
  phase: string;
  attackCard: (ownCardPlayId: number, opponentCardPlayId: number) => void;
  attackPlayer: (ownCardPlayId: number) => void;
  activityAttackTarget: (attackerUniquePlayId: number, targetUniquePlayId: number) => void;
  activityCurrentAttackerUniquePlayId: number;
  activityCurrentTargetUniquePlayId: number;
  setTarget: (cardPlayId: number) => void;
  targetCard: Card | null;
  gameState: GameUpdate;
}

interface GameState {
  selectedAttackCard?: number;
  numAttacked: number;
}

class GameBoard extends React.PureComponent<GameBoardProps, GameState> {
  public readonly state: Readonly<GameState> = { numAttacked: 0 };

  public componentDidUpdate(prevProps: GameBoardProps): void {
    if (prevProps.turn !== this.props.turn && this.state.selectedAttackCard !== undefined) {
      this.setState({ selectedAttackCard: undefined });
    }
  }

  public render(): React.ReactNode {
    let selectedAttackCard: Card | undefined;

    if (this.state.selectedAttackCard !== undefined) {
      selectedAttackCard = this.props.playerCards.find(
        playerCard => playerCard.uniquePlayId === this.state.selectedAttackCard,
      );
    }

    return (
      <div className={styles.Wrapper}>
        <Tutorial
          step="opponentCards"
          gameState={this.props.gameState}
          position="bottom"
          additionalInfo={{
            attackCardSelected: this.state.selectedAttackCard !== undefined,
            numAttacked: this.state.numAttacked,
          }}
          padding={-35}
        >
          <div className={styles.OpponentWrapper}>
            <Tutorial
              step="opponentAvatar"
              gameState={this.props.gameState}
              position="right"
              additionalInfo={{
                attackCardSelected: this.state.selectedAttackCard !== undefined,
                numAttacked: this.state.numAttacked,
              }}
            >
              <PlayerAvatar
                username={this.props.opponent}
                health={this.props.opponentHealthPoints}
                actionPoints={this.props.opponentActionPoints}
                avatar={OpponentAvatarImage}
                canAttack={
                  this.props.phase === 'attack' &&
                  this.state.selectedAttackCard !== undefined &&
                  !this.props.opponentCards.some(card => card.mode === 'defense')
                }
                onAttack={(): void => {
                  if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                    this.props.attackPlayer(this.state.selectedAttackCard);
                    this.setState(state => ({ selectedAttackCard: undefined, numAttacked: state.numAttacked + 1 }));
                  }
                }}
                onMouseEnter={(): void => {
                  if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                    this.props.activityAttackTarget(this.state.selectedAttackCard, -1);
                  }
                }}
                onMouseLeave={(): void => {
                  if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                    this.props.activityAttackTarget(this.state.selectedAttackCard, 0);
                  }
                }}
                className={styles.AvatarWrapper}
              />
            </Tutorial>
            <div className={styles.ScrollWrapper}>
              <TransitionGroup className={styles.CardsWrapper}>
                {this.props.opponentCards.map((card: Card) => (
                  <CSSTransition
                    key={card.uniquePlayId}
                    timeout={600}
                    classNames={{
                      enter: styles.CardEnter,
                      enterActive: styles.CardEnterActive,
                      exit: styles.CardExit,
                      exitActive: styles.CardExitActive,
                    }}
                  >
                    <div
                      key={card.uniquePlayId}
                      className={classNames(styles.CardWrapper, {
                        [styles.DefenseCard]: card.mode === 'defense',
                        [styles.AttackableCard]:
                          this.props.phase === 'attack' &&
                          selectedAttackCard &&
                          ((card.mode === 'defense' &&
                            card.defensePoints +
                              (card.equipmentCard ? card.equipmentCard.defensePoints : 0) +
                              (card.spellCard ? card.spellCard.defensePoints : 0) -
                              (card.opponentSpellCard ? card.opponentSpellCard.defensePoints : 0) <=
                              selectedAttackCard.attackPoints +
                                (selectedAttackCard.equipmentCard ? selectedAttackCard.equipmentCard.attackPoints : 0) +
                                (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                (selectedAttackCard.opponentSpellCard
                                  ? selectedAttackCard.opponentSpellCard.attackPoints
                                  : 0)) ||
                            (card.mode === 'attack' &&
                              card.attackPoints +
                                (card.equipmentCard ? card.equipmentCard.attackPoints : 0) +
                                (card.spellCard ? card.spellCard.attackPoints : 0) -
                                (card.opponentSpellCard ? card.opponentSpellCard.attackPoints : 0) <=
                                selectedAttackCard.attackPoints +
                                  (selectedAttackCard.equipmentCard
                                    ? selectedAttackCard.equipmentCard.attackPoints
                                    : 0) +
                                  (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                  (selectedAttackCard.opponentSpellCard
                                    ? selectedAttackCard.opponentSpellCard.attackPoints
                                    : 0))),
                        [styles.SelectedCard]: this.props.activityCurrentAttackerUniquePlayId === card.uniquePlayId,
                      })}
                    >
                      <CardComponent
                        cardId={card.cardId}
                        cardData={card}
                        onClick={(): void => {
                          if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                            this.props.attackCard(this.state.selectedAttackCard, card.uniquePlayId);
                            this.setState(state => ({
                              selectedAttackCard: undefined,
                              numAttacked: state.numAttacked + 1,
                            }));
                          }
                        }}
                        onMouseEnter={(): void => {
                          if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                            this.props.activityAttackTarget(this.state.selectedAttackCard, card.uniquePlayId);
                          }
                        }}
                        onMouseLeave={(): void => {
                          if (this.props.phase === 'attack' && this.state.selectedAttackCard !== undefined) {
                            this.props.activityAttackTarget(this.state.selectedAttackCard, 0);
                          }
                        }}
                        /* greyes out cards when they are not attackable */
                        className={classNames({
                          [styles.SilencedCard]:
                            this.props.phase === 'attack' &&
                            selectedAttackCard &&
                            ((card.mode === 'defense' &&
                              card.defensePoints +
                                (card.equipmentCard ? card.equipmentCard.defensePoints : 0) +
                                (card.spellCard ? card.spellCard.defensePoints : 0) -
                                (card.opponentSpellCard ? card.opponentSpellCard.defensePoints : 0) >
                                selectedAttackCard.attackPoints +
                                  (selectedAttackCard.equipmentCard
                                    ? selectedAttackCard.equipmentCard.attackPoints
                                    : 0) +
                                  (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                  (selectedAttackCard.opponentSpellCard
                                    ? selectedAttackCard.opponentSpellCard.attackPoints
                                    : 0)) ||
                              (card.mode === 'attack' &&
                                card.attackPoints +
                                  (card.equipmentCard ? card.equipmentCard.attackPoints : 0) +
                                  (card.spellCard ? card.spellCard.attackPoints : 0) -
                                  (card.opponentSpellCard ? card.opponentSpellCard.attackPoints : 0) >
                                  selectedAttackCard.attackPoints +
                                    (selectedAttackCard.equipmentCard
                                      ? selectedAttackCard.equipmentCard.attackPoints
                                      : 0) +
                                    (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                    (selectedAttackCard.opponentSpellCard
                                      ? selectedAttackCard.opponentSpellCard.attackPoints
                                      : 0))),
                        })}
                      />
                      {/* shows opponentSpellCards that were placed by the opponent */}
                      {card.opponentSpellCard && (
                        <CardComponent
                          cardId={card.opponentSpellCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]:
                                !card.spellCard && !card.equipmentCard && card.mode === 'attack',
                              [styles.EquipmentSpellSlot2]:
                                (card.spellCard || card.equipmentCard) && card.mode === 'attack',
                              [styles.EquipmentSpellSlot3]:
                                card.spellCard && card.equipmentCard && card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]:
                                !card.spellCard && !card.equipmentCard && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot2]:
                                (card.spellCard || card.equipmentCard) && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot3]:
                                card.spellCard && card.equipmentCard && card.mode === 'defense',
                            },
                            {
                              [styles.SilencedCard]:
                                this.props.phase === 'attack' &&
                                selectedAttackCard &&
                                ((card.mode === 'defense' &&
                                  card.defensePoints +
                                    (card.equipmentCard ? card.equipmentCard.defensePoints : 0) +
                                    (card.spellCard ? card.spellCard.defensePoints : 0) -
                                    (card.opponentSpellCard ? card.opponentSpellCard.defensePoints : 0) >
                                    selectedAttackCard.attackPoints +
                                      (selectedAttackCard.equipmentCard
                                        ? selectedAttackCard.equipmentCard.attackPoints
                                        : 0) +
                                      (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                      (selectedAttackCard.opponentSpellCard
                                        ? selectedAttackCard.opponentSpellCard.attackPoints
                                        : 0)) ||
                                  (card.mode === 'attack' &&
                                    card.attackPoints +
                                      (card.equipmentCard ? card.equipmentCard.attackPoints : 0) +
                                      (card.spellCard ? card.spellCard.attackPoints : 0) -
                                      (card.opponentSpellCard ? card.opponentSpellCard.attackPoints : 0) >
                                      selectedAttackCard.attackPoints +
                                        (selectedAttackCard.equipmentCard
                                          ? selectedAttackCard.equipmentCard.attackPoints
                                          : 0) +
                                        (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                        (selectedAttackCard.opponentSpellCard
                                          ? selectedAttackCard.opponentSpellCard.attackPoints
                                          : 0))),
                            },
                          )}
                        />
                      )}
                      {/* shows spellCards that were placed by the opponent */}
                      {card.spellCard && (
                        <CardComponent
                          cardId={card.spellCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]: !card.equipmentCard && card.mode === 'attack',
                              [styles.EquipmentSpellSlot2]: card.equipmentCard && card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]: !card.equipmentCard && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot2]: card.equipmentCard && card.mode === 'defense',
                            },
                            {
                              [styles.SilencedCard]:
                                this.props.phase === 'attack' &&
                                selectedAttackCard &&
                                ((card.mode === 'defense' &&
                                  card.defensePoints +
                                    (card.equipmentCard ? card.equipmentCard.defensePoints : 0) +
                                    (card.spellCard ? card.spellCard.defensePoints : 0) -
                                    (card.opponentSpellCard ? card.opponentSpellCard.defensePoints : 0) >
                                    selectedAttackCard.attackPoints +
                                      (selectedAttackCard.equipmentCard
                                        ? selectedAttackCard.equipmentCard.attackPoints
                                        : 0) +
                                      (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                      (selectedAttackCard.opponentSpellCard
                                        ? selectedAttackCard.opponentSpellCard.attackPoints
                                        : 0)) ||
                                  (card.mode === 'attack' &&
                                    card.attackPoints +
                                      (card.equipmentCard ? card.equipmentCard.attackPoints : 0) +
                                      (card.spellCard ? card.spellCard.attackPoints : 0) -
                                      (card.opponentSpellCard ? card.opponentSpellCard.attackPoints : 0) >
                                      selectedAttackCard.attackPoints +
                                        (selectedAttackCard.equipmentCard
                                          ? selectedAttackCard.equipmentCard.attackPoints
                                          : 0) +
                                        (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                        (selectedAttackCard.opponentSpellCard
                                          ? selectedAttackCard.opponentSpellCard.attackPoints
                                          : 0))),
                            },
                          )}
                        />
                      )}
                      {/* shows equipmentCards that were placed by the oponnent */}
                      {card.equipmentCard && (
                        <CardComponent
                          cardId={card.equipmentCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]: card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]: card.mode === 'defense',
                            },
                            {
                              [styles.SilencedCard]:
                                this.props.phase === 'attack' &&
                                selectedAttackCard &&
                                ((card.mode === 'defense' &&
                                  card.defensePoints +
                                    (card.equipmentCard ? card.equipmentCard.defensePoints : 0) +
                                    (card.spellCard ? card.spellCard.defensePoints : 0) -
                                    (card.opponentSpellCard ? card.opponentSpellCard.defensePoints : 0) >
                                    selectedAttackCard.attackPoints +
                                      (selectedAttackCard.equipmentCard
                                        ? selectedAttackCard.equipmentCard.attackPoints
                                        : 0) +
                                      (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                      (selectedAttackCard.opponentSpellCard
                                        ? selectedAttackCard.opponentSpellCard.attackPoints
                                        : 0)) ||
                                  (card.mode === 'attack' &&
                                    card.attackPoints +
                                      (card.equipmentCard ? card.equipmentCard.attackPoints : 0) +
                                      (card.spellCard ? card.spellCard.attackPoints : 0) -
                                      (card.opponentSpellCard ? card.opponentSpellCard.attackPoints : 0) >
                                      selectedAttackCard.attackPoints +
                                        (selectedAttackCard.equipmentCard
                                          ? selectedAttackCard.equipmentCard.attackPoints
                                          : 0) +
                                        (selectedAttackCard.spellCard ? selectedAttackCard.spellCard.attackPoints : 0) -
                                        (selectedAttackCard.opponentSpellCard
                                          ? selectedAttackCard.opponentSpellCard.attackPoints
                                          : 0))),
                            },
                          )}
                        />
                      )}
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </div>
        </Tutorial>
        <Tutorial
          step="ownCards"
          gameState={this.props.gameState}
          additionalInfo={{
            attackCardSelected: this.state.selectedAttackCard !== undefined,
            numAttacked: this.state.numAttacked,
          }}
          padding={-35}
        >
          <div className={styles.PlayerWrapper}>
            <PlayerAvatar
              username={this.props.playerName}
              health={this.props.playerHealthPoints}
              actionPoints={this.props.playerActionPoints}
              avatar={PlayerAvatarImage}
              className={styles.AvatarWrapper}
              activityAttack={this.props.activityCurrentTargetUniquePlayId === -1}
            />
            <div className={styles.ScrollWrapper}>
              <TransitionGroup className={styles.CardsWrapper}>
                {this.props.playerCards.map((card: Card) => (
                  <CSSTransition
                    key={card.uniquePlayId}
                    timeout={600}
                    classNames={{
                      enter: styles.CardEnter,
                      enterActive: styles.CardEnterActive,
                      exit: styles.CardExit,
                      exitActive: styles.CardExitActive,
                    }}
                  >
                    <div
                      className={classNames(styles.CardWrapper, {
                        [styles.DefenseCard]: card.mode === 'defense',
                        [`${styles.ActivityAttack} ${styles.AttackableCard}`]:
                          this.props.activityCurrentTargetUniquePlayId === card.uniquePlayId,
                      })}
                    >
                      <CardComponent
                        cardId={card.cardId}
                        cardData={card}
                        onClick={(): void => {
                          if (
                            this.props.phase === 'attack' &&
                            this.props.turn !== this.props.opponent &&
                            !card.isPlayed &&
                            card.mode === 'attack'
                          ) {
                            this.props.activityAttackTarget(card.uniquePlayId, 0);
                            this.setState(() => ({ selectedAttackCard: card.uniquePlayId }));
                          } else if (
                            this.props.targetCard !== null &&
                            this.props.targetCard.isEquipment &&
                            !this.props.targetCard.isSpell &&
                            this.props.targetCard.type === card.type &&
                            !card.equipmentCard
                          ) {
                            this.props.setTarget(card.uniquePlayId);
                          } else if (
                            this.props.targetCard !== null &&
                            this.props.targetCard.isSpell &&
                            !this.props.targetCard.isEquipment &&
                            !card.spellCard
                          ) {
                            this.props.setTarget(card.uniquePlayId);
                          }
                        }}
                        className={classNames({
                          [styles.SelectedCard]:
                            this.props.phase === 'attack' && this.state.selectedAttackCard === card.uniquePlayId,
                          [styles.SilencedCard]:
                            (this.props.phase === 'attack' &&
                              this.props.turn === this.props.playerName &&
                              ((this.state.selectedAttackCard !== undefined &&
                                this.state.selectedAttackCard !== card.uniquePlayId) ||
                                card.isPlayed ||
                                card.mode !== 'attack')) ||
                            ((this.props.targetCard !== null &&
                              this.props.targetCard.isEquipment &&
                              this.props.targetCard.type !== card.type) ||
                              (this.props.targetCard !== null &&
                                this.props.targetCard.isEquipment &&
                                card.equipmentCard)) ||
                            (this.props.targetCard !== null && this.props.targetCard.isSpell && card.spellCard),
                        })}
                      />
                      {/* shows placed opponentSpellCards */}
                      {card.opponentSpellCard && (
                        <CardComponent
                          cardId={card.opponentSpellCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]:
                                !card.spellCard && !card.equipmentCard && card.mode === 'attack',
                              [styles.EquipmentSpellSlot2]:
                                (card.spellCard || card.equipmentCard) && card.mode === 'attack',
                              [styles.EquipmentSpellSlot3]:
                                card.spellCard && card.equipmentCard && card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]:
                                !card.spellCard && !card.equipmentCard && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot2]:
                                (card.spellCard || card.equipmentCard) && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot3]:
                                card.spellCard && card.equipmentCard && card.mode === 'defense',
                            },
                            {
                              [styles.SelectedCard]:
                                this.props.phase === 'attack' && this.state.selectedAttackCard === card.uniquePlayId,
                              [styles.SilencedCard]:
                                (this.props.phase === 'attack' &&
                                  ((this.state.selectedAttackCard !== undefined &&
                                    this.state.selectedAttackCard !== card.uniquePlayId) ||
                                    card.isPlayed ||
                                    (card.mode !== 'attack' && this.props.turn !== this.props.opponent))) ||
                                (this.props.targetCard !== null && this.props.targetCard.isSpell && card.spellCard) ||
                                (this.props.targetCard !== null &&
                                  this.props.targetCard.isEquipment &&
                                  card.equipmentCard),
                            },
                          )}
                        />
                      )}
                      {/* shows placed spellCards */}
                      {card.spellCard && (
                        <CardComponent
                          cardId={card.spellCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]: !card.equipmentCard && card.mode === 'attack',
                              [styles.EquipmentSpellSlot2]: card.equipmentCard && card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]: !card.equipmentCard && card.mode === 'defense',
                              [styles.DefenseEquipmentSpellSlot2]: card.equipmentCard && card.mode === 'defense',
                            },
                            {
                              [styles.SelectedCard]:
                                this.props.phase === 'attack' && this.state.selectedAttackCard === card.uniquePlayId,
                              [styles.SilencedCard]:
                                (this.props.phase === 'attack' &&
                                  ((this.state.selectedAttackCard !== undefined &&
                                    this.state.selectedAttackCard !== card.uniquePlayId) ||
                                    card.isPlayed ||
                                    (card.mode !== 'attack' && this.props.turn !== this.props.opponent))) ||
                                (this.props.targetCard !== null && this.props.targetCard.isSpell && card.spellCard) ||
                                (this.props.targetCard !== null &&
                                  this.props.targetCard.isEquipment &&
                                  card.equipmentCard),
                            },
                          )}
                        />
                      )}
                      {/* shows placed equipmentCards */}
                      {card.equipmentCard && (
                        <CardComponent
                          cardId={card.equipmentCard.cardId}
                          className={classNames(
                            styles.AdditionalCard,
                            {
                              [styles.EquipmentSpellSlot1]: card.mode === 'attack',
                              [styles.DefenseEquipmentSpellSlot1]: card.mode === 'defense',
                            },
                            {
                              [styles.SelectedCard]:
                                this.props.phase === 'attack' && this.state.selectedAttackCard === card.uniquePlayId,
                              [styles.SilencedCard]:
                                (this.props.phase === 'attack' &&
                                  ((this.state.selectedAttackCard !== undefined &&
                                    this.state.selectedAttackCard !== card.uniquePlayId) ||
                                    card.isPlayed ||
                                    (card.mode !== 'attack' && this.props.turn !== this.props.opponent))) ||
                                (this.props.targetCard !== null &&
                                  this.props.targetCard.isEquipment &&
                                  this.props.targetCard.type !== card.type) ||
                                (this.props.targetCard !== null &&
                                  card.equipmentCard &&
                                  this.props.targetCard.isEquipment) ||
                                (this.props.targetCard !== null && card.spellCard && this.props.targetCard.isSpell),
                            },
                          )}
                        />
                      )}
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </div>
        </Tutorial>
        <div className={styles.Turn}>
          Turn: <strong>{this.props.turn === this.props.playerName ? 'You' : this.props.turn}</strong>
        </div>
        <div className={styles.NextTurnButtonWrapper}>{this.props.nextTurnButton(this.state.numAttacked)}</div>
      </div>
    );
  }
}

export default GameBoard;
