import React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './styles.module.css';
import CardbackComponent from '../CardbackComponent';

interface OpponentHandProps {
  cards: number[];
  canPlace: boolean;
  hoveredCard?: number;
}

class OpponentHand extends React.PureComponent<OpponentHandProps> {
  public render(): React.ReactNode {
    return (
      <TransitionGroup className={classNames(styles.Wrapper, { [styles.CastPhase]: this.props.canPlace })}>
        <div className={styles.CardsWrapper}>
          {this.props.cards.map((cardUniqueId: number) => (
            <CSSTransition
              key={cardUniqueId}
              timeout={300}
              classNames={{
                enter: styles.CardEnter,
                enterActive: styles.CardEnterActive,
                exit: styles.CardExit,
                exitActive: styles.CardExitActive,
              }}
            >
              <div
                className={classNames(styles.CardWrapper, {
                  [styles.Hovered]: cardUniqueId === this.props.hoveredCard,
                })}
              >
                <CardbackComponent cardUniqueId={cardUniqueId} />
              </div>
            </CSSTransition>
          ))}
        </div>
      </TransitionGroup>
    );
  }
}

export default OpponentHand;
