import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Home from './pages/Home';
import Register from './pages/Register';
import Navigation from './components/Navigation';
import { User } from './types/user';
import Play from './pages/Play';
import Decks from './pages/Decks';
import Deckpreview from './pages/Deckpreview';
import About from './pages/About';
import CardStore from './pages/CardStore';
import DeckStore from './pages/DeckStore';
import Leaderboard from './pages/Leaderboard';

interface AppState {
  user: null | User;
}

class App extends React.PureComponent<{}, AppState> {
  public readonly state: Readonly<AppState> = { user: null };

  public constructor(props: {}) {
    super(props);

    const rawToken = localStorage.getItem('@battlebeasts/token');
    if (rawToken) {
      const parsed: { exp: number; username: string; email: string } = jwtDecode(rawToken);

      // check if the jwt token is still valid
      if (parsed && parsed.exp * 1000 > new Date().getTime()) {
        this.state = { user: { ...parsed, token: rawToken } };
      } else {
        localStorage.removeItem('@battlebeasts/token');
      }
    }
  }

  public onLogin(token: string): void {
    localStorage.setItem('@battlebeasts/token', token);
    const parsed: { exp: number; username: string; email: string } = jwtDecode(token);

    this.setState({ user: { ...parsed, token } });
  }

  public onLogout(): void {
    localStorage.removeItem('@battlebeasts/token');
    this.setState({ user: null });
  }

  public render(): React.ReactNode {
    return (
      <>
        <Router>
          <Navigation
            onLogin={this.onLogin.bind(this)}
            onLogout={this.onLogout.bind(this)}
            isLoggedIn={this.state.user !== null}
            userNav={this.state.user !== null ? this.state.user : undefined}
          />
          <Switch>
            <Route path="/register">
              <Register isLoggedIn={this.state.user !== null} />
            </Route>
            <Route path="/cardstore">{this.state.user && <CardStore user={this.state.user} />}</Route>
            <Route path="/deckstore">{this.state.user && <DeckStore user={this.state.user} />}</Route>
            <Route path="/deckpreview">{this.state.user && <Deckpreview user={this.state.user} />}</Route>
            <Route path="/decks/:id" component={Deckpreview}>
              {this.state.user && <Decks user={this.state.user} />}
            </Route>
            <Route path="/play/:gameModeId/:deckId">{this.state.user && <Play user={this.state.user} />}</Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/leaderboard">
              {this.state.user ? <Leaderboard user={this.state.user} /> : <Leaderboard />}
            </Route>
            <Route path="/">{this.state.user ? <Home user={this.state.user} /> : <Home />}</Route>
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
