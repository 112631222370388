import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string;
  touched?: boolean;
}

class TextInput extends React.PureComponent<TextInputProps> {
  public render(): React.ReactNode {
    const { error, touched, ...props } = this.props;
    const hasError = touched && error;

    return (
      <>
        <input className={classNames(styles.Input, { [styles.InputError]: hasError })} {...props} />
        {hasError && <div className={styles.Error}>{this.props.error}</div>}
      </>
    );
  }
}

export default TextInput;
