import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { CardService } from '../../services/cardService';
import DefenceIcon from '../../assets/images/about/defense-icon.png';
import AttackIcon from '../../assets/images/about/attack-icon.png';
import ActionIcon from '../../assets/images/about/action-icon.png';
import CarnivoreIcon from '../../assets/images/about/carnivore-icon.png';
import HerbivoreIcon from '../../assets/images/about/herbivore-icon.png';
import FishIcon from '../../assets/images/about/fish-icon.png';
import EquipmentIcon from '../../assets/images/about/equipment-icon.png';
import SpellIcon from '../../assets/images/about/spell-icon.png';
import { Card } from '../../types/card';

interface CardProps {
  cardId: number;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  hideDescription?: boolean;
  cardData?: Card;
}

interface CardState {
  name: string;
  type: string;
  description: string;
  attackPoints: number;
  defensePoints: number;
  actionPoints: number;
  isEquipment: boolean | undefined;
  isSpell: boolean | undefined;
  isCarnivore: boolean | undefined;
  isHerbivore: boolean | undefined;
  isFish: boolean | undefined;
  isNotAnimal: boolean | undefined;
  back?: boolean;
}

class CardComponent extends React.PureComponent<CardProps, CardState> {
  constructor(props: Readonly<CardProps>) {
    super(props);
    this.state = {
      name: 'turtle',
      type: 'Herbivore',
      description: 'unloadedcard',
      attackPoints: 0,
      defensePoints: 0,
      actionPoints: 0,
      isEquipment: false,
      isSpell: false,
      isCarnivore: false,
      isHerbivore: true,
      isFish: false,
      isNotAnimal: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadCard();
  }

  public async componentDidUpdate(prevProps: CardProps): Promise<void> {
    if (prevProps.cardId !== this.props.cardId) {
      await this.loadCard();
    }
  }

  private getIcon(): string | undefined {
    if (this.state.isCarnivore && this.state.isNotAnimal) {
      return CarnivoreIcon;
    }
    if (this.state.isHerbivore && this.state.isNotAnimal) {
      return HerbivoreIcon;
    }
    if (this.state.isFish && this.state.isNotAnimal) {
      return FishIcon;
    }
    if (this.state.isEquipment) {
      return EquipmentIcon;
    }
    if (this.state.isSpell) {
      return SpellIcon;
    }
    return undefined;
  }

  private async loadCard(): Promise<void> {
    const { cardId } = this.props;
    const card = await CardService.getCard(cardId);

    // check if the loaded card is still the correct one to display or if the id has changed in the meantime
    if (cardId === this.props.cardId) {
      this.setState({
        name: card.name,
        type: card.type,
        description: card.description,
        attackPoints: card.attackPoints,
        defensePoints: card.defensePoints,
        actionPoints: card.actionPoints,
        isEquipment: card.isEquipment,
        isSpell: card.isSpell,
      });
      this.checkForAnimal(this.state.isEquipment, this.state.isSpell);
      this.checkForCarnivore(this.state.type, this.state.isNotAnimal);
      this.checkForHerbivore(this.state.type, this.state.isNotAnimal);
      this.checkForFish(this.state.type, this.state.isNotAnimal);
    }
  }

  private checkForAnimal(isEquipment: boolean | undefined, isSpell: boolean | undefined): void {
    if ((isEquipment === undefined || false) && (isSpell === undefined || false)) {
      this.setState({ isNotAnimal: true });
    } else {
      this.setState({ isNotAnimal: false });
    }
  }

  private checkForCarnivore(typeName: string, isNotAnimal: boolean | undefined): void {
    if (typeName === 'Carnivore' && isNotAnimal) {
      this.setState({ isCarnivore: true });
    } else {
      this.setState({ isCarnivore: false });
    }
  }

  private checkForHerbivore(typeName: string, isNotAnimal: boolean | undefined): void {
    if (typeName === 'Herbivore' && isNotAnimal) {
      this.setState({ isHerbivore: true });
    } else {
      this.setState({ isHerbivore: false });
    }
  }

  private checkForFish(typeName: string, isNotAnimal: boolean | undefined): void {
    if (typeName === 'Fish' && isNotAnimal) {
      this.setState({ isFish: true });
    } else {
      this.setState({ isFish: false });
    }
  }

  public render(): React.ReactNode {
    let { attackPoints, defensePoints } = this.state;

    if (this.props.cardData) {
      // add equipment card values
      attackPoints += (this.props.cardData.equipmentCard || {}).attackPoints || 0;
      defensePoints += (this.props.cardData.equipmentCard || {}).defensePoints || 0;

      // add spell card values
      attackPoints += (this.props.cardData.spellCard || {}).attackPoints || 0;
      defensePoints += (this.props.cardData.spellCard || {}).defensePoints || 0;

      // add opponent spell card values
      attackPoints -= (this.props.cardData.opponentSpellCard || {}).attackPoints || 0;
      defensePoints -= (this.props.cardData.opponentSpellCard || {}).defensePoints || 0;
    }

    const CardDetails = (
      <div>
        <div className={styles.TopWrapper}>
          <div className={styles.TypeWrapper}>
            <img src={this.getIcon()} alt="icon" />
          </div>
          <p>{this.state.name}</p>
        </div>
        <img
          className={styles.CardImage}
          src={`${process.env.REACT_APP_API_BASE_URL}/images/${this.props.cardId}_card.jpg`}
          alt={this.state.name}
        />
        {this.props.hideDescription ? null : <p className={styles.CardDescription}>{this.state.description}</p>}
        <div className={styles.BottomWrapper}>
          <p>{this.state.type}</p>
          <div className={styles.CardStats}>
            <span>
              <img src={AttackIcon} alt="DMG" />
              <span className={styles.DesktopStat}>{this.state.attackPoints}</span>
              <span
                className={classNames(styles.MobileStat, {
                  [styles.StatIncreased]: attackPoints > this.state.attackPoints,
                  [styles.StatDecreased]: attackPoints < this.state.attackPoints,
                })}
              >
                {attackPoints}
              </span>
            </span>
            <span>
              <img src={DefenceIcon} alt="DEF" />
              <span className={styles.DesktopStat}>{this.state.defensePoints}</span>
              <span
                className={classNames(styles.MobileStat, {
                  [styles.StatIncreased]: defensePoints > this.state.defensePoints,
                  [styles.StatDecreased]: defensePoints < this.state.defensePoints,
                })}
              >
                {defensePoints}
              </span>
            </span>
            <span>
              <img src={ActionIcon} alt="AP" />
              {this.state.actionPoints}
            </span>
          </div>
        </div>
      </div>
    );
    const CardStyle = (
      <div
        className={classNames(styles.InnerLayer, {
          [styles.Herbivore]: this.state.isHerbivore,
          [styles.Carnivore]: this.state.isCarnivore,
          [styles.Fish]: this.state.isFish,
          [styles.Equipment]: this.state.isEquipment,
          [styles.Spell]: this.state.isSpell && this.state.type === 'Spell',
          [styles.Boost]: this.state.isSpell && this.state.type === 'Boost',
        })}
      >
        {CardDetails}
      </div>
    );
    return (
      <div
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={classNames(styles.Wrapper, this.props.className)}
      >
        <div
          className={classNames(styles.OuterLayer, {
            [styles.OuterLayerSmall]: this.props.hideDescription,
          })}
        >
          {CardStyle}
        </div>
      </div>
    );
  }
}

export default CardComponent;
