import React from 'react';
import queryString, { ParsedQuery } from 'query-string';
import styles from './styles.module.css';
import Box from '../../components/Box';
import { User } from '../../types/user';
import Main from '../../components/Main';
import Button from '../../components/Button';
import { HttpResponse } from '../../services/apiService';
import { PaymentService } from '../../services/paymentService';

interface DeckStoreProps {
  user: User;
}

interface BoughtDecksState {
  decks?: [any];
  error?: string;
  count: number;
  amount: number;
  payerId?: string;
  paymentId?: string;
  token?: string;
}

class DeckStore extends React.PureComponent<DeckStoreProps, BoughtDecksState> {
  private static DECK_PRICE = 5;

  private static MAX_NEW_DECKS = 7;

  private boughtDeckspaces: any;

  constructor(props: Readonly<DeckStoreProps>) {
    super(props);
    const parsedQuery: ParsedQuery<string> = queryString.parse(window.location.search);
    if (parsedQuery.PayerID && parsedQuery.paymentId && parsedQuery.token) {
      this.state = {
        count: this.getCountCookie(),
        amount: 5,
        payerId: parsedQuery.PayerID as string,
        paymentId: parsedQuery.paymentId as string,
        token: parsedQuery.token as string,
      };
    } else {
      this.state = {
        count: 1,
        amount: 5,
      };
    }
  }

  private getCountCookie(): number {
    // get cookie
    const countEntry = document.cookie.split(';').find(entry => {
      return entry.includes('count');
    });
    if (countEntry) {
      const count = +countEntry.split('=')[1];
      return count;
    }
    return 1;
  }

  private setBoughtDeckspacesList(): void {
    if (this.state.decks) {
      this.setState(this.boughtDeckspaces);
    }
  }

  private increment = (): void => {
    if (this.state.count < DeckStore.MAX_NEW_DECKS) {
      this.setState(state => ({
        count: state.count + 1,
        amount: state.amount + DeckStore.DECK_PRICE,
      }));
    }
  };

  private decrement = (): void => {
    if (this.state.count > 1) {
      this.setState(state => ({
        count: state.count - 1,
        amount: state.amount - DeckStore.DECK_PRICE,
      }));
    }
  };

  private cancelPayment(): void {
    this.setState({ payerId: '', paymentId: '', token: '' });
  }

  private async redirectToPayPal(): Promise<void> {
    try {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const paypalRes: HttpResponse<string> = await PaymentService.initializePaymentforDeckSpaces(
        this.props.user.token,
        this.state.count,
      );

      // set cookie
      document.cookie = `count=${this.state.count}`;

      const redirectURLToPaypal: string = await paypalRes.json();
      window.location.replace(redirectURLToPaypal);
    } catch {
      this.setState({
        error: 'Error while redirecting to paypal.',
      });
    }
  }

  private async executePaymentAndGetDeckspaces(): Promise<void> {
    try {
      // delete cookie not needed anymore
      document.cookie = 'count= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';

      const decksRes = await PaymentService.executePaymentAndGetBoughtDeckspaces(
        this.props.user.token,
        this.state.count,
        this.state.payerId,
        this.state.paymentId,
      );
      const unlockedDeckspaces = await decksRes.json();
      this.setState(prevState => ({
        ...prevState,
        decks: unlockedDeckspaces,
        error: undefined,
      }));
      this.setBoughtDeckspacesList();
    } catch {
      this.setState({
        error: 'Error while executing payment. Please try again.',
      });
    } finally {
      this.setState({
        count: 1,
        payerId: '',
        paymentId: '',
        token: '',
      });
    }
  }

  private renderPaymentOrBuyBox(): React.ReactNode {
    if (!this.state.paymentId && !this.state.payerId && !this.state.token) {
      return (
        <Box>
          <div className={styles.Wrapper}>
            <div className={styles.StoreWrapper}>
              <h3>Deckspace</h3>
              Unlock {this.state.count} new&nbsp;
              <b title="Buy more deckspace">
                Deckspace
                {this.state.count > 1 ? <b>s</b> : null}
              </b>
              &nbsp;for {this.state.amount.toPrecision(2)} CHF.
              <br />
              <div className={styles.ButtonWrapper}>
                <Button onClick={this.increment}>+</Button>
                <Button onClick={this.decrement}>-</Button>
                <Button onClick={this.redirectToPayPal.bind(this)}>Buy</Button>
              </div>
              <div>
                {this.state.error ? <p>Sorry... {this.state.error}</p> : null}
                {this.state.error && this.state.count > 1 ? <p>Or counter too high.</p> : null}
                {this.state.decks && this.state.error === undefined ? (
                  <p style={{ color: 'green' }}>
                    Purchase successful.
                    <br />
                    Thank you!
                  </p>
                ) : null}
              </div>
              <div className={styles.CardWrapper}>
                <ul>{this.boughtDeckspaces}</ul>
              </div>
            </div>
          </div>
        </Box>
      );
    }
    return (
      <Box>
        <div className={styles.StoreWrapper}>
          <h3>Confirm payment</h3>
          <div className={styles.ButtonWrapper}>
            <Button onClick={this.executePaymentAndGetDeckspaces.bind(this)}>Confirm payment</Button>
            <Button onClick={this.cancelPayment.bind(this)}>Cancel payment</Button>
            <br />
          </div>
          <div>
            {this.state.error ? (
              <p>Sorry... {this.state.error}</p>
            ) : (
              <p>
                Are you sure you want to buy {this.state.count} decks for {this.state.count * DeckStore.DECK_PRICE} CHF?
              </p>
            )}
          </div>
        </div>
      </Box>
    );
  }

  public render(): React.ReactNode {
    return (
      <Main>
        <div className={styles.Wrapper}>{this.renderPaymentOrBuyBox()}</div>
      </Main>
    );
  }
}

export default DeckStore;
