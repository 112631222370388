import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { DeckService } from '../../services/deckService';
import { User } from '../../types/user';
import { Deck } from '../../types/deck';
import styles from './styles.module.css';
import Button from '../Button';
import Guide from '../Guide';

interface SelectDeckProps {
  user: User;
}

interface SelectDeckState {
  decks: Deck[];
  selectedDeck?: string;
  aiDifficulty?: string;
}

class SelectDeck extends React.PureComponent<SelectDeckProps, SelectDeckState> {
  public readonly state: Readonly<SelectDeckState> = { decks: [] };

  constructor(props: SelectDeckProps) {
    super(props);

    DeckService.getDeck(props.user.token)
      .then(res => res.json())
      .then((decks: Deck[]): void => {
        this.setState({ decks, aiDifficulty: 'ai-easy' });
      });
  }

  private printAiDifficulty(): string {
    switch (this.state.aiDifficulty) {
      case 'ai-medium':
        return 'AI-Medium';
      case 'ai-hard':
        return 'AI-Hard';
      default:
        return 'AI-Easy';
    }
  }

  public render(): React.ReactNode {
    return (
      <>
        <Guide instructions="playMode" position="left" decks={this.state.decks} disable={!this.state.selectedDeck}>
          <Link to={`/play/casual/${this.state.selectedDeck}`}>
            <Button
              className={styles.PlayButton}
              type="button"
              disabled={typeof this.state.selectedDeck === 'undefined'}
            >
              Play Casual
            </Button>
          </Link>
        </Guide>
        <Link to={`/play/ranked/${this.state.selectedDeck}`}>
          <Button className={styles.PlayButton} type="button" disabled={typeof this.state.selectedDeck === 'undefined'}>
            Play Ranked
          </Button>
        </Link>
        <br />
        <Link to={`/play/${this.state.aiDifficulty}/${this.state.selectedDeck}`}>
          <Button className={styles.PlayButton} type="button" disabled={typeof this.state.selectedDeck === 'undefined'}>
            Play vs Bot
          </Button>
        </Link>
        <span className={styles.Difficulty}>
          <input
            type="range"
            min="0"
            max="2"
            defaultValue="0"
            className={styles.Slider}
            onChange={(e): void => {
              switch (e.target.value) {
                case '1':
                  this.setState({ aiDifficulty: 'ai-medium' });
                  break;
                case '2':
                  this.setState({ aiDifficulty: 'ai-hard' });
                  break;
                default:
                  this.setState({ aiDifficulty: 'ai-easy' });
                  break;
              }
            }}
          />
          {this.printAiDifficulty()}
        </span>
        <p className={styles.SelectInfo}>Please select a deck you want to play with:</p>

        <div className={styles.DeckSelector}>
          {this.state.decks.map(deck => {
            return (
              <Fragment key={deck._id}>
                <input
                  id={deck._id}
                  type="radio"
                  name="deckSelection"
                  onChange={(): void => this.setState({ selectedDeck: deck._id })}
                />
                <Guide
                  instructions="deckPreview"
                  position="right"
                  decks={this.state.decks}
                  disable={
                    !(this.state.decks[this.state.decks.length - 1].name === deck.name && !this.state.selectedDeck)
                  }
                >
                  <label htmlFor={deck._id}>{deck.name}</label>
                </Guide>
              </Fragment>
            );
          })}
          <Guide instructions="manageDeck" position="right" decks={this.state.decks}>
            <Link to="/deckpreview">Manage decks</Link>
          </Guide>
        </div>
      </>
    );
  }
}

export default SelectDeck;
