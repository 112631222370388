import React from 'react';
import queryString, { ParsedQuery } from 'query-string';
import styles from './styles.module.css';
import Box from '../../components/Box';
import { User } from '../../types/user';
import Main from '../../components/Main';
import UnlockedCard from '../../components/UnlockedCard';
import Button from '../../components/Button';
import { PaymentService } from '../../services/paymentService';
import { HttpResponse } from '../../services/apiService';

interface CardStoreProps {
  user: User;
}

interface BoughtCardsState {
  cards?: [any];
  error?: string;
  count: number;
  amount: number;
  payerId?: string;
  paymentId?: string;
  token?: string;
}

class CardStore extends React.PureComponent<CardStoreProps, BoughtCardsState> {
  private static CARD_PRICE = 0.95;

  private static MAX_NEW_CARDS = 7;

  private boughtCards: any;

  constructor(props: Readonly<CardStoreProps>) {
    super(props);

    const parsedQuery: ParsedQuery<string> = queryString.parse(window.location.search);
    if (parsedQuery.PayerID && parsedQuery.paymentId && parsedQuery.token) {
      this.state = {
        count: this.getCountCookie(),
        amount: 0.95,
        payerId: parsedQuery.PayerID as string,
        paymentId: parsedQuery.paymentId as string,
        token: parsedQuery.token as string,
      };
    } else {
      this.state = {
        count: 1,
        amount: 0.95,
      };
    }
  }

  private getCountCookie(): number {
    // get cookie
    const countEntry = document.cookie.split(';').find(entry => {
      return entry.includes('count');
    });
    if (countEntry) {
      const count = +countEntry.split('=')[1];
      return count;
    }
    return 1;
  }

  private setBoughtCardList(): void {
    if (this.state.cards) {
      this.boughtCards = this.state.cards.map(card => (
        <li key={card.cardId}>
          <UnlockedCard rewardCardId={card.cardId} />
        </li>
      ));
      this.setState(this.boughtCards);
    }
  }

  private increment = (): void => {
    if (this.state.count < CardStore.MAX_NEW_CARDS) {
      this.setState(state => ({
        count: state.count + 1,
        amount: state.amount + CardStore.CARD_PRICE,
      }));
    }
  };

  private decrement = (): void => {
    if (this.state.count > 1) {
      this.setState(state => ({
        count: state.count - 1,
        amount: state.amount - CardStore.CARD_PRICE,
      }));
    }
  };

  private cancelPayment(): void {
    this.setState({ payerId: '', paymentId: '', token: '' });
  }

  private async redirectToPayPal(): Promise<void> {
    try {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const paypalRes: HttpResponse<string> = await PaymentService.initializePayment(
        this.props.user.token,
        this.state.count,
      );

      // set cookie
      document.cookie = `count=${this.state.count}`;

      const redirectURLToPaypal: string = await paypalRes.json();
      window.location.replace(redirectURLToPaypal);
    } catch {
      this.setState({
        error: 'Error while redirecting to paypal.',
      });
    }
  }

  private async executePaymentAndGetCards(): Promise<void> {
    try {
      // delete cookie not needed anymore
      document.cookie = 'count= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';

      const cardsRes = await PaymentService.executePaymentAndGetBoughtCards(
        this.props.user.token,
        this.state.count,
        this.state.payerId,
        this.state.paymentId,
      );
      const unlockedCards = await cardsRes.json();
      this.setState({ cards: unlockedCards, error: undefined });
      this.setBoughtCardList();
    } catch {
      this.setState({
        error:
          'Error while executing payment. It could be that no more unowned cards are available or counter is too high.',
      });
    } finally {
      this.setState({
        count: 1,
        payerId: '',
        paymentId: '',
        token: '',
      });
    }
  }

  private renderPaymentOrBuyBox(): React.ReactNode {
    if (!this.state.paymentId && !this.state.payerId && !this.state.token) {
      return (
        <Box>
          <div className={styles.StoreWrapper}>
            <h3>Mystery cards</h3>
            Unlock {this.state.count} new&nbsp;
            <b title="A card which you don't posses yet. No duplicates of cards you already own.">
              mystery card
              {this.state.count > 1 ? <b>s</b> : null}
            </b>
            &nbsp;for {this.state.amount.toPrecision(2)} CHF.
            <br />
            <div className={styles.ButtonWrapper}>
              <Button onClick={this.increment}>+</Button>
              <Button onClick={this.decrement}>-</Button>
              <Button onClick={this.redirectToPayPal.bind(this)}>Buy</Button>
            </div>
            <div>
              {this.state.error ? <p>Sorry... {this.state.error}</p> : null}
              {this.state.error && this.state.count > 1 ? <p>Or counter too high.</p> : null}
              {this.state.cards && this.state.error === undefined ? (
                <p style={{ color: 'green' }}>
                  Purchase successful.
                  <br />
                  Thank you!
                </p>
              ) : null}
            </div>
            <div className={styles.CardWrapper}>
              <ul>{this.boughtCards}</ul>
            </div>
          </div>
        </Box>
      );
    }
    return (
      <Box>
        <div className={styles.StoreWrapper}>
          <h3>Confirm payment</h3>
          <div className={styles.ButtonWrapper}>
            <Button onClick={this.executePaymentAndGetCards.bind(this)}>Confirm payment</Button>
            <Button onClick={this.cancelPayment.bind(this)}>Cancel payment</Button>
          </div>
          <div>
            {this.state.error ? (
              <p>Sorry... {this.state.error}</p>
            ) : (
              <p>
                Are you sure you want to buy {this.state.count} cards for {this.state.count * CardStore.CARD_PRICE} CHF?
              </p>
            )}
          </div>
        </div>
      </Box>
    );
  }

  public render(): React.ReactNode {
    return (
      <Main>
        <div className={styles.Wrapper}>{this.renderPaymentOrBuyBox()}</div>
      </Main>
    );
  }
}

export default CardStore;
