import React, { ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './styles.module.css';
import Box from '../../components/Box';
import Main from '../../components/Main';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import FormControl from '../../components/FormControl';
import { UserService } from '../../services/userService';

interface RegisterProps {
  isLoggedIn: boolean;
}

interface RegisterState {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  response: string;
}

class RegisterForm extends React.Component<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      password_confirmation: '',
      response: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // stores changes from register form in state
  handleChange(event: ChangeEvent<HTMLInputElement>): void {
    // checks from which input the event comes
    switch (event.target.getAttribute('name')) {
      case 'username':
        this.setState({ username: event.target.value });
        break;
      case 'email':
        this.setState({ email: event.target.value });
        break;
      case 'password':
        this.setState({ password: event.target.value });
        break;
      case 'password_confirmed':
        this.setState({ password_confirmation: event.target.value });
        break;
      default:
        break;
    }
  }

  public render(): React.ReactNode {
    if (this.props.isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <Main>
        <div className={styles.Wrapper}>
          <Box title="Register a new account" size="small">
            <form
              className="register-form"
              onSubmit={async (event): Promise<void> => {
                event.preventDefault();
                const res = await UserService.postRegister(this.state);
                const data = await res.json();

                // checks if response is a error message else redirects to start page
                if (data.error) {
                  this.setState({ response: data.error });
                } else {
                  localStorage.setItem('@battlebeasts/token', data.token);
                  window.location.href = '/';
                }
              }}
            >
              {this.state.response && <p className={styles.Error}>{this.state.response}</p>}
              <FormControl>
                <label>
                  <span>Username:</span>
                  <TextInput
                    name="username"
                    type="text"
                    value={this.state.username}
                    onChange={this.handleChange}
                    placeholder="HansMeier"
                  />
                </label>
              </FormControl>
              <FormControl>
                <label>
                  <span>Email:</span>
                  <TextInput
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="hans@gmx.com"
                  />
                </label>
              </FormControl>
              <FormControl>
                <label>
                  <span>Password:</span>
                  <TextInput name="password" type="password" value={this.state.password} onChange={this.handleChange} />
                </label>
              </FormControl>
              <FormControl>
                <label>
                  <span>Confirm password:</span>
                  <TextInput
                    name="password_confirmed"
                    type="password"
                    value={this.state.password_confirmation}
                    onChange={this.handleChange}
                  />
                </label>
              </FormControl>
              <Button type="submit">Register</Button>
            </form>
          </Box>
        </div>
      </Main>
    );
  }
}

export default RegisterForm;
