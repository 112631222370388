import React from 'react';
import Popover, { ArrowContainer, PopoverProps } from 'react-tiny-popover';
import styles from './styles.module.css';
import instructions from './instructions';
import { Deck } from '../../types/deck';

export interface GuideProps {
  children: React.ReactElement;
  instructions: keyof typeof instructions;
  decks: Deck[];
  position: PopoverProps['position'];
  disable?: boolean;
  padding?: number;
}

class Guide extends React.PureComponent<GuideProps> {
  private isInstructionActive = false;

  public render(): React.ReactNode {
    this.isInstructionActive = instructions[this.props.instructions].active(this.props.decks);

    if (parseInt(localStorage.getItem('@battlebeasts/fullGamesPlayed') || '0', 10) > 0) {
      return this.props.children;
    }

    return (
      <Popover
        isOpen={this.props.disable ? false : this.isInstructionActive}
        position={this.props.position}
        padding={this.props.padding}
        containerStyle={{ zIndex: '10', pointerEvents: 'none' }}
        content={({ position, targetRect, popoverRect }): React.ReactElement => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor="#e9e9ec"
            arrowSize={10}
          >
            <div className={styles.Popover}>{instructions[this.props.instructions].content()}</div>
          </ArrowContainer>
        )}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export default Guide;
