import React from 'react';
import { Formik, Form } from 'formik';
import styles from './styles.module.css';
import TextInput from '../TextInput';
import Button from '../Button';
import { UserService } from '../../services/userService';

interface LoginDropdownProps {
  onLogin: (token: string) => void;
}

interface LoginDropdownState {
  loginError?: string;
}

class LoginDropdown extends React.PureComponent<LoginDropdownProps, LoginDropdownState> {
  public readonly state: Readonly<LoginDropdownState> = {};

  public render(): React.ReactNode {
    return (
      <div className={`${styles.LoginWrapper} login-form`}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={(values): object => {
            const errors: { username?: string; password?: string } = {};

            if (!values.username) {
              errors.username = 'Required';
            }

            if (!values.password) {
              errors.password = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }): Promise<void> => {
            if (this.state.loginError) {
              this.setState({ loginError: undefined });
            }

            const res = await UserService.postLogin(values);

            const data = await res.json();

            if (data.error) {
              this.setState({ loginError: data.error });
              setSubmitting(false);
            } else {
              this.props.onLogin(data.token);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }): React.ReactNode => (
            <Form>
              <TextInput
                type="text"
                name="username"
                placeholder="Username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={this.state.loginError ? this.state.loginError : errors.username}
                touched={this.state.loginError ? true : touched.username}
              />
              <TextInput
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
              />
              <Button type="submit" disabled={isSubmitting}>
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default LoginDropdown;
