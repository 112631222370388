import React from 'react';
import Popover, { ArrowContainer, PopoverProps } from 'react-tiny-popover';
import steps, { AdditionalInfo } from './steps';
import styles from './styles.module.css';
import { GameUpdate } from '../../types/gameUpdate';

export interface TutorialProps {
  children: React.ReactElement;
  step: keyof typeof steps;
  gameState: GameUpdate;
  padding?: number;
  additionalInfo?: AdditionalInfo;
  position?: PopoverProps['position'];
}

class Tutorial extends React.PureComponent<TutorialProps> {
  public render(): React.ReactNode {
    // only show tutorial for the first time playing
    if (parseInt(localStorage.getItem('@battlebeasts/fullGamesPlayed') || '0', 10) > 0) {
      return this.props.children;
    }

    const isActive = steps[this.props.step].active(this.props.gameState, this.props.additionalInfo);

    // trigger resize event after next render to force a re-layout of the popover
    // since the content of the same popover can change which is not handled by the package
    if (isActive) {
      setTimeout(() => window.dispatchEvent(new Event('resize')));
    }

    return (
      <Popover
        isOpen={isActive}
        position={this.props.position || 'top'}
        padding={this.props.padding}
        containerStyle={{ zIndex: '10', pointerEvents: 'none' }}
        content={({ position, targetRect, popoverRect }): React.ReactElement => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor="#e9e9ec"
            arrowSize={10}
          >
            <div className={styles.Popover}>
              {steps[this.props.step].content(this.props.gameState, this.props.additionalInfo)}
            </div>
          </ArrowContainer>
        )}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export default Tutorial;
