import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import Box from '../../components/Box';
import { User } from '../../types/user';
import { Deck } from '../../types/deck';
import Main from '../../components/Main';
import { DeckService } from '../../services/deckService';
import Button from '../../components/Button';
import Guide from '../../components/Guide';

interface DecksProps {
  user: User;
}

interface DecksState {
  decks: Deck[];
  ownedDeckSpaces: number;
  selectedDeckName?: string;
  error?: string;
}

class Deckpreview extends React.PureComponent<DecksProps, DecksState> {
  public readonly state: Readonly<DecksState> = {
    decks: [],
    ownedDeckSpaces: 0,
  };

  public async componentDidMount(): Promise<void> {
    const userDecksJson = await (await DeckService.getDeck(this.props.user.token)).json();
    if (userDecksJson.error) {
      this.setState({ error: userDecksJson.error });
    } else {
      this.setState({ decks: userDecksJson, error: undefined });
    }

    const userDeckSpaces = await (await DeckService.getDeckSpaces(this.props.user.token)).json();
    if (userDecksJson.error) {
      this.setState({ error: userDecksJson.error });
    } else {
      this.setState({ ownedDeckSpaces: userDeckSpaces.owned, error: undefined });
    }
  }

  private async deleteDeck(deckName: string): Promise<void> {
    const leftOverDecks = await (await DeckService.deleteDeck(`${this.props.user.token}`, deckName)).json();
    if (leftOverDecks.error) {
      this.setState({ error: leftOverDecks.error });
    } else {
      this.setState({ decks: leftOverDecks, error: undefined });
    }
  }

  public render(): React.ReactNode {
    return (
      <Main>
        <div className={styles.CenterWrapper}>
          <div className={styles.DecksInfo}>
            <h3>
              {this.state.decks.length}/{this.state.ownedDeckSpaces} deck spaces
            </h3>
            <Link to="/cardstore">
              <Button>Buy mystery card</Button>
            </Link>
            <Link to="/deckstore">
              <Button>Buy more deckspace</Button>
            </Link>
          </div>
          <div className={styles.DeckSelector}>
            {this.state.decks.length < this.state.ownedDeckSpaces ? (
              <Guide instructions="createDeck" position="bottom" decks={this.state.decks}>
                <Link to="/decks/newDeck">+ create Deck</Link>
              </Guide>
            ) : null}
            {this.state.decks.map(deck => {
              return (
                <div className={styles.CreatedDecks} key={deck._id}>
                  <Link key={deck._id} to={`/decks/${deck.name}`}>
                    {deck.name}
                  </Link>
                  <Button onClick={(): Promise<void> => this.deleteDeck(deck.name)}>Delete Deck</Button>
                </div>
              );
            })}
          </div>
          <div>{this.state.error ? <Box>An Error occured: {this.state.error}</Box> : null}</div>
        </div>
      </Main>
    );
  }
}

export default Deckpreview;
