import React from 'react';
import 'swiper/css/swiper.css';
import styles from './styles.module.css';
import Main from '../../components/Main';
import Container from '../../components/Container';
import { UserService } from '../../services/userService';
import { User } from '../../types/user';

interface LeaderboardProps {
  user?: User;
}
interface UserSkillAndUsername {
  rank: number;
  username: string;
  skill: string;
}

interface LeaderboardState {
  userTopTen: UserSkillAndUsername[];
  userAroundYourRank: UserSkillAndUsername[];
  error: string;
}

class Leaderboard extends React.PureComponent<LeaderboardProps, LeaderboardState> {
  public static AMOUNT_OF_USERS_TO_DISPLAY = 5;

  constructor(props: Readonly<LeaderboardProps>) {
    super(props);

    this.state = {
      userTopTen: [],
      userAroundYourRank: [],
      error: '',
    };
  }

  public async componentDidMount(): Promise<void> {
    try {
      const toptenUsersRes = await UserService.getTopTenUsers();
      const toptenUsers = await toptenUsersRes.json();
      this.setState({ userTopTen: toptenUsers });
    } catch {
      this.setState({ error: 'There was an error loading the skill tables.' });
    }

    if (this.props.user) {
      try {
        const usersAroundCurrUsersRankRes = await UserService.getUsersAroundCurrUsersRank(this.props.user.token);
        const usersAroundCurrUsersRank = await usersAroundCurrUsersRankRes.json();
        this.setState({ userAroundYourRank: usersAroundCurrUsersRank });
      } catch {
        this.setState({ error: 'There was an error loading the skill tables.' });
      }
    }
  }

  private createTdElement(tdElements: JSX.Element[], user: UserSkillAndUsername, attribute: string): void {
    tdElements.push(<td className={attribute}>{user.rank}.</td>);
    tdElements.push(<td className={attribute}>{user.username}</td>);
    tdElements.push(<td className={attribute}>{user.skill}</td>);
  }

  private renderOneUserRow(user: UserSkillAndUsername): JSX.Element[] {
    const tdElements: JSX.Element[] = [];

    if (this.props.user && user.username === this.props.user.username) {
      // if current user style the row differently
      this.createTdElement(tdElements, user, styles.currentUser);
    } else {
      this.createTdElement(tdElements, user, '');
    }

    return tdElements;
  }

  private renderTable(users: UserSkillAndUsername[]): JSX.Element {
    const trElements: JSX.Element[] = [];
    users.forEach(user => {
      const userRow = this.renderOneUserRow(user);
      trElements.push(<tr>{userRow}</tr>);
    });

    const tableHeader = [
      <tr key="th">
        <th>Rank</th>
        <th>Username</th>
        <th>Skill</th>
      </tr>,
    ];
    return (
      <table>
        <thead>{tableHeader}</thead>
        <tbody>{trElements}</tbody>
      </table>
    );
  }

  public render(): React.ReactNode {
    return (
      <Main>
        <Container>
          {this.state.error ? (
            <div className={styles.Card}>
              <p className={styles.errorMessage}>{this.state.error}</p>
            </div>
          ) : (
            <div className={styles.Card}>
              {this.props.user ? (
                <div className={styles.AroundYourRankWrapper}>
                  <h2 className={styles.Title}>Around your rank</h2>
                  {this.renderTable(this.state.userAroundYourRank)}
                </div>
              ) : null}
              <div className={styles.TopTenWrapper}>
                {this.props.user ? null : <p>Log in or register to see your own rank!</p>}
                <h2 className={styles.Title}>Top ten Battle Beasts</h2>
                {this.renderTable(this.state.userTopTen)}
              </div>
            </div>
          )}
        </Container>
      </Main>
    );
  }
}

export default Leaderboard;
