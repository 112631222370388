import React from 'react';
import { GameUpdate } from '../../types/gameUpdate';

export interface AdditionalInfo {
  handCard?: number;
  selectedHandCard?: number;
  attackCardSelected?: boolean;
  numAttacked?: number;
}

export default {
  hand: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      gameState.forPlayer === gameState.playerTurn &&
      gameState.round.turn < 2 &&
      gameState.boardCards[gameState.forPlayer].length < 2 &&
      (!additionalInfo || typeof additionalInfo.selectedHandCard === 'undefined'),
    content: (gameState: GameUpdate): React.ReactNode => {
      if (gameState.boardCards[gameState.forPlayer].length === 0) {
        return (
          <div>
            Those are your available cards. You start with <b>5</b> cards and get an additional one every round.
            <br />
            When hovering over a card, you see more details and its stats.
            <br />
            The icons at the bottom right indicate (from left to right): <b>Attack points</b>, <b>Defense points</b> and{' '}
            <b>Action points</b>.
            <br />
            To place a card, simply click on it. You can only place a card if you have enough <b>Action points</b> left.
          </div>
        );
      }

      return (
        <div>
          You can now place additional cards as long as you have enough <b>action points</b> left.
          <br />
          If you don&apos;t have enough action points to place a card, it will get greyed out.
          <br />
          Don&apos;t worry about using all your action points, they get <b>refilled</b> every turn.
        </div>
      );
    },
  },
  selectedHandCard: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      gameState.forPlayer === gameState.playerTurn &&
      gameState.round.turn < 2 &&
      gameState.boardCards[gameState.forPlayer].length < 1 &&
      typeof additionalInfo !== 'undefined' &&
      typeof additionalInfo.handCard !== 'undefined' &&
      typeof additionalInfo.selectedHandCard !== 'undefined' &&
      additionalInfo.selectedHandCard === additionalInfo.handCard,
    content: (): React.ReactNode => {
      return (
        <div>
          You can either place a card in <b>attack</b> or <b>defense</b> mode.
          <br />
          <br />
          When in <b>attack mode</b>, it can attack the cards of your opponent.
          <br />
          <br />
          When in <b>defense mode</b>, it can&apos;t attack other cards
          <br />
          but your opponent always has to attack this card first before he can
          <br />
          directly attack your player and reduce your health points.
          <br />
          <br />
          To start, you should place at least one card in <b>attack mode</b>.
        </div>
      );
    },
  },
  nextphase: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      (gameState.forPlayer === gameState.playerTurn &&
        gameState.round.turn < 2 &&
        gameState.round.phase === 'cast' &&
        gameState.boardCards[gameState.forPlayer].length > 1) ||
      (gameState.forPlayer !== gameState.playerTurn && gameState.round.turn < 2) ||
      (gameState.forPlayer === gameState.playerTurn &&
        gameState.round.phase === 'attack' &&
        gameState.round.turn < 3 &&
        typeof additionalInfo !== 'undefined' &&
        typeof additionalInfo.numAttacked !== 'undefined' &&
        additionalInfo.numAttacked > 0) ||
      (gameState.forPlayer === gameState.playerTurn && gameState.round.phase === 'cast' && gameState.round.turn === 2),
    content: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): React.ReactNode => (
      <div>
        {gameState.forPlayer === gameState.playerTurn && gameState.round.phase === 'cast' && gameState.round.turn < 2 && (
          <>
            Once you have placed all the cards you want,
            <br />
            you can click on this button to {gameState.round.turn === 0 ? 'end your turn' : 'proceed to the next phase'}
            .
            <br />
            {gameState.round.turn === 0
              ? 'Your opponent will then have the chance to place his cards.'
              : 'In the next phase, you have the chance to attack your opponent.'}
          </>
        )}
        {gameState.forPlayer === gameState.playerTurn &&
          gameState.round.phase === 'attack' &&
          typeof additionalInfo !== 'undefined' &&
          typeof additionalInfo.numAttacked !== 'undefined' &&
          additionalInfo.numAttacked > 0 && (
            <>
              Once you attacked as many cards as you want,
              <br />
              you can click on this button to end your turn.
              <br />
              <br />
              The cast and attack phases will now repeat until
              <br />a player has no health points left.
            </>
          )}
        {gameState.forPlayer !== gameState.playerTurn &&
          gameState.round.turn === 0 &&
          'Your opponent starts and can place his cards first.'}
        {gameState.forPlayer !== gameState.playerTurn &&
          gameState.round.turn === 1 &&
          gameState.round.phase === 'cast' &&
          'It is now your opponents turn to place his cards.'}
        {gameState.forPlayer !== gameState.playerTurn &&
          gameState.round.turn === 1 &&
          gameState.round.phase === 'attack' &&
          'You opponent now attacks your cards. When he is done, it is your turn again.'}
        {gameState.forPlayer === gameState.playerTurn &&
          gameState.round.phase === 'cast' &&
          gameState.round.turn === 2 && (
            <>
              Now, it is your turn again.
              <br />
              First, place some new cards and when you are ready,
              <br />
              click here to proceed to the attack phase.
            </>
          )}
      </div>
    ),
  },
  ownCards: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      gameState.forPlayer === gameState.playerTurn &&
      gameState.round.phase === 'attack' &&
      gameState.round.turn < 3 &&
      typeof additionalInfo !== 'undefined' &&
      (typeof additionalInfo.numAttacked === 'undefined' || additionalInfo.numAttacked < 1) &&
      additionalInfo.attackCardSelected !== true,
    content: (): React.ReactNode => (
      <div>
        First, select a card you want to use to attack your opponent.
        <br />
        You can only select cards which are in attack mode.
      </div>
    ),
  },
  opponentCards: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      gameState.forPlayer === gameState.playerTurn &&
      gameState.round.phase === 'attack' &&
      gameState.round.turn < 3 &&
      typeof additionalInfo !== 'undefined' &&
      (typeof additionalInfo.numAttacked === 'undefined' || additionalInfo.numAttacked < 1) &&
      additionalInfo.attackCardSelected === true,
    content: (): React.ReactNode => (
      <div>
        Now, select a card which you want to attack. Cards which you can&apos;t attack are greyed out.
        <br />
        <br />
        If the card of your opponent is in <b>attack mode</b>, the attack points of your card need
        <br />
        to be bigger than the <b>attack points</b> of your opponent&apos;s card.
        <br />
        <br />
        If the card of your opponent is in <b>defense mode</b>, the attack points of your card need
        <br />
        to be bigger than the <b>defense points</b> of your opponent&apos;s card.
        <br />
        <br />
        When a card gets destroyed, it is no longer available for the remainder of the current game.
      </div>
    ),
  },
  opponentAvatar: {
    active: (gameState: GameUpdate, additionalInfo?: AdditionalInfo): boolean =>
      gameState.forPlayer === gameState.playerTurn &&
      gameState.round.phase === 'attack' &&
      gameState.round.turn < 3 &&
      typeof additionalInfo !== 'undefined' &&
      typeof additionalInfo.numAttacked !== 'undefined' &&
      additionalInfo.numAttacked === 1 &&
      additionalInfo.attackCardSelected !== true,
    content: (): React.ReactNode => (
      <div>
        If your opponent has no card in <b>defense mode</b> left,
        <br />
        you can also directly attack him with one of your cards
        <br />
        to reduce his health points (first, select one of your cards
        <br />
        and then click on this avatar).
        <br />
        <br />
        Once a player has no health points left, he has lost the game.
      </div>
    ),
  },
};
