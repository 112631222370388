import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './styles.module.css';

interface NavigationItemProps {
  href?: string;
  dropdownContent?: React.ReactNode;
  hoverEffect?: boolean;
}

interface NavigationItemState {
  dropdownOpen: boolean;
}

class NavigationItem extends React.PureComponent<NavigationItemProps, NavigationItemState> {
  public readonly state: Readonly<NavigationItemState> = { dropdownOpen: false };

  public onOutsideClick(event: React.MouseEvent<HTMLElement>): void {
    this.setState({ dropdownOpen: false });
    event.preventDefault();
    event.stopPropagation();
  }

  public render(): React.ReactNode {
    const { hoverEffect = true } = this.props;

    return (
      <li
        className={classNames(styles.Wrapper, {
          [styles.ItemDropdownOpen]: this.state.dropdownOpen,
          [styles.HoverEffect]: hoverEffect,
        })}
      >
        {this.props.href ? (
          <Link to={this.props.href} className={styles.Item}>
            {this.props.children}
          </Link>
        ) : (
          <span
            className={styles.Item}
            onClick={
              this.props.dropdownContent
                ? (): void =>
                    this.setState(state => ({
                      dropdownOpen: !state.dropdownOpen,
                    }))
                : undefined
            }
          >
            {this.props.children}
          </span>
        )}
        {this.props.dropdownContent && (
          <OutsideClickHandler onOutsideClick={this.onOutsideClick.bind(this)}>
            <div className={classNames(styles.Dropdown, { [styles.DropdownOpen]: this.state.dropdownOpen })}>
              {this.props.dropdownContent}
            </div>
          </OutsideClickHandler>
        )}
      </li>
    );
  }
}

export default NavigationItem;
