import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { User } from '../../types/user';
import Box from '../../components/Box';
import { Deck } from '../../types/deck';
import styles from './styles.module.css';
import OpponentHand from '../../components/OpponentHand';
import GameBoard from '../../components/GameBoard';
import { GameUpdate } from '../../types/gameUpdate';
import Hand from '../../components/Hand';
import Button from '../../components/Button';
import AfterGameOptions from '../../components/AfterGameOptions';
import Tutorial from '../../components/Tutorial';
import UnlockedCard from '../../components/UnlockedCard';
import { DeckService } from '../../services/deckService';

interface PlayProps extends RouteComponentProps<{ deckId: string; gameModeId: string }> {
  user: User;
  socketClient?: typeof io;
}

interface PlayState {
  inGame: boolean;
  opponent?: string;
  winner?: string;
  gameState?: GameUpdate;
  rewardCardId?: { cardId: number; duplicate: boolean };
  targetMode: number | null;
  isDeckValid?: boolean;
  opponentHoveredCard?: number;
  activityCurrentAttackerUniquePlayId: number;
  activityCurrentTargetUniquePlayId: number;
}

export class Play extends React.PureComponent<PlayProps, PlayState> {
  public readonly state: Readonly<PlayState> = {
    inGame: false,
    isDeckValid: true,
    targetMode: null,
    activityCurrentAttackerUniquePlayId: 0,
    activityCurrentTargetUniquePlayId: 0,
  };

  private clients: { queue?: SocketIOClient.Socket; game?: SocketIOClient.Socket } = {};

  public async componentDidMount(): Promise<void> {
    let decks: Deck[] = [];
    const userDecksRes = await DeckService.getDeck(`${this.props.user.token}`);
    const userDecksJson = await userDecksRes.json();
    if (!userDecksJson.error) {
      decks = userDecksJson;
    }
    if (this.isDeckOwnedByPlayer(this.props.match.params.deckId, decks)) {
      this.setState({ isDeckValid: true });
      if (this.props.match.params.gameModeId.startsWith('ai')) {
        this.joinGameRoom(`${this.props.user.username}/${new Date().getTime()}`);
      } else {
        this.searchOpponent();
      }
    } else {
      this.setState({ isDeckValid: false });
    }
  }

  public componentWillUnmount(): void {
    if (this.clients.queue) {
      this.clients.queue.disconnect();
    }

    if (this.clients.game) {
      this.clients.game.disconnect();
    }
  }

  private getNextPhase(): string {
    if (!this.state.gameState) {
      return 'unknown state';
    }

    if (this.state.gameState.playerTurn !== this.props.user.username) {
      return 'Opponent\'s turn';
    }

    if (
      (this.state.gameState.round.turn === 0 && this.state.gameState.round.phase === 'cast') ||
      this.state.gameState.round.phase === 'attack'
    ) {
      return 'End turn';
    }

    switch (this.state.gameState.round.phase) {
      case 'start':
        return 'Next phase: cast';
      case 'cast':
        return 'Next phase: attack';
      default:
        return 'Next phase: start';
    }
  }

  private isDeckOwnedByPlayer(deckId: string, decks: Deck[]): boolean {
    return decks.map(deck => deck._id).includes(deckId);
  }

  private searchOpponent(): void {
    // connect to matchmaking server
    const queue = (this.props.socketClient || io)(`${process.env.REACT_APP_API_BASE_URL}/matchmaking`, {
      query: { token: this.props.user.token },
    });

    // handle new game room notification
    queue.on('gameRoom', (gameRoomId: string) => {
      queue.disconnect();
      this.clients.queue = undefined;
      this.joinGameRoom(gameRoomId);
    });

    // join the queue
    const ranked: boolean = this.props.match.params.gameModeId.startsWith('ranked');
    queue.emit('join', ranked);

    this.clients.queue = queue;
  }

  private joinGameRoom(gameRoomId: string): void {
    // connect to game server
    const connection = (this.props.socketClient || io)(`${process.env.REACT_APP_API_BASE_URL}/game`, {
      query: {
        token: this.props.user.token,
        gameRoomId,
        mode: this.props.match.params.gameModeId,
        deck: this.props.match.params.deckId,
      },
    });

    connection.on('opponentInfo', (opponent: string) => {
      this.setState(() => ({
        inGame: true,
        opponent,
      }));
    });

    connection.on('gameUpdate', (gameUpdate: GameUpdate) => {
      this.setState(() => ({
        gameState: gameUpdate,
        activityCurrentAttackerUniquePlayId: 0,
        activityCurrentTargetUniquePlayId: 0,
      }));
    });

    connection.on('opponentHoveredHandCard', (cardPlayId: number) => {
      this.setState(() => ({
        opponentHoveredCard: cardPlayId,
      }));
    });

    connection.on('opponentActivityAttackTarget', (attackerUniquePlayId: number, targetUniquePlayId: number) => {
      this.setState({
        activityCurrentAttackerUniquePlayId: attackerUniquePlayId,
        activityCurrentTargetUniquePlayId: targetUniquePlayId,
      });
    });

    connection.on('gameOver', (winner: string, disconnected: boolean): void => {
      this.setState(() => ({
        winner,
      }));

      if (!disconnected) {
        const gamesPlayed = parseInt(localStorage.getItem('@battlebeasts/fullGamesPlayed') || '0', 10);

        localStorage.setItem('@battlebeasts/fullGamesPlayed', `${gamesPlayed + 1}`);
      }
    });

    connection.on('unlockedCards', (reward: { cardId: number; duplicate: boolean }[]): void => {
      this.setState(() => ({
        rewardCardId: reward[0],
      }));
    });

    this.clients.game = connection;
  }

  public render(): React.ReactNode {
    if (!this.state.inGame || !this.state.opponent || !this.state.gameState) {
      if (this.state.isDeckValid) {
        return (
          <div className={styles.BoxWrapper}>
            <Box>
              <h3>Searching opponent...</h3>
              <Link to="/">
                <Button type="button">Cancel Search</Button>
              </Link>
            </Box>
          </div>
        );
      }

      return (
        <div className={styles.BoxWrapper}>
          <Box>
            <h3>Your deck doesn&apos;t seem to exist or belong to you...</h3>
            <Link to="/">
              <Button type="button">Go back</Button>
            </Link>
          </Box>
        </div>
      );
    }

    const nextPhaseButton = (numAttacked: number): React.ReactNode => {
      if (!this.state.gameState) {
        return null;
      }

      return (
        <Tutorial step="nextphase" gameState={this.state.gameState} additionalInfo={{ numAttacked }}>
          <Button
            disabled={this.state.gameState.playerTurn !== this.props.user.username}
            onClick={(): void => {
              if (this.clients.game) {
                this.clients.game.emit('nextPhase');

                if (this.state.targetMode !== null) {
                  this.setState({ targetMode: null });
                }
              }
            }}
          >
            {this.getNextPhase()}
          </Button>
        </Tutorial>
      );
    };

    return (
      <div className={styles.GameWrapper}>
        {this.state.winner && (
          <div className={styles.GameOver}>
            {this.state.winner === this.props.user.username ? (
              <Box title="Game over" color="green">
                Congratulations! You have won!
                <AfterGameOptions />
                {this.state.rewardCardId !== undefined ? (
                  <UnlockedCard rewardCardId={this.state.rewardCardId.cardId} />
                ) : null}
              </Box>
            ) : (
              <Box title="Game over" color="red">
                {this.state.opponent} has won. Maybe next time.
                <AfterGameOptions />
                {this.state.rewardCardId !== undefined ? (
                  <UnlockedCard rewardCardId={this.state.rewardCardId.cardId} />
                ) : null}
              </Box>
            )}
          </div>
        )}
        <OpponentHand
          cards={this.state.gameState.opponentHandCards}
          canPlace={
            this.state.gameState.playerTurn === this.state.opponent && this.state.gameState.round.phase === 'cast'
          }
          hoveredCard={this.state.opponentHoveredCard}
        />
        <GameBoard
          playerName={this.props.user.username}
          playerCards={this.state.gameState.boardCards[this.props.user.username]}
          playerHealthPoints={this.state.gameState.healthPoints[this.props.user.username]}
          playerActionPoints={this.state.gameState.actionPoints[this.props.user.username]}
          opponent={this.state.opponent}
          opponentCards={this.state.gameState.boardCards[this.state.opponent]}
          opponentHealthPoints={this.state.gameState.healthPoints[this.state.opponent]}
          opponentActionPoints={this.state.gameState.actionPoints[this.state.opponent]}
          turn={this.state.gameState.playerTurn}
          nextTurnButton={nextPhaseButton}
          phase={this.state.gameState.round.phase}
          attackCard={(ownCardPlayId: number, opponentCardPlayId: number): void => {
            if (this.clients.game) {
              this.clients.game.emit('attackCard', ownCardPlayId, opponentCardPlayId);
            }
          }}
          attackPlayer={(ownCardPlayId: number): void => {
            if (this.clients.game) {
              this.clients.game.emit('attackPlayer', ownCardPlayId);
            }
          }}
          activityAttackTarget={(attackerUniquePlayId: number, targetUniquePlayId: number): void => {
            if (this.clients.game) {
              this.clients.game.emit('activityAttackTarget', attackerUniquePlayId, targetUniquePlayId);
            }
          }}
          activityCurrentAttackerUniquePlayId={this.state.activityCurrentAttackerUniquePlayId}
          activityCurrentTargetUniquePlayId={this.state.activityCurrentTargetUniquePlayId}
          targetCard={
            this.state.targetMode !== null
              ? this.state.gameState.handCards.find(handCard => handCard.uniquePlayId === this.state.targetMode) || null
              : null
          }
          setTarget={(cardPlayId): void => {
            if (this.clients.game) {
              this.clients.game.emit('placeEquipmentOrSpell', this.state.targetMode, cardPlayId);
            }

            this.setState({
              targetMode: null,
            });
          }}
          gameState={this.state.gameState}
        />
        <Hand
          gameState={this.state.gameState}
          cards={this.state.gameState.handCards}
          boardCards={this.state.gameState.boardCards[this.props.user.username]}
          hoverCard={(hoveredCardPlayId): void => {
            if (this.clients.game) {
              this.clients.game.emit('hoveredHandCard', hoveredCardPlayId);
            }
          }}
          canPlace={
            this.state.gameState.playerTurn === this.props.user.username && this.state.gameState.round.phase === 'cast'
          }
          placeCard={(cardPlayId: number, mode: 'attack' | 'defense'): void => {
            if (this.clients.game) {
              this.clients.game.emit('placeCard', cardPlayId, mode);
            }
          }}
          placeSpell={(cardPlayId: number): void => {
            if (this.clients.game) {
              this.clients.game.emit('placeSpell', cardPlayId);
            }
          }}
          enableTargetMode={(cardPlayId): void => this.setState({ targetMode: cardPlayId })}
          targetMode={this.state.targetMode}
          actionPoints={this.state.gameState.actionPoints[this.props.user.username]}
        />
      </div>
    );
  }
}

export default withRouter(Play);
