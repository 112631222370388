import React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './styles.module.css';
import CardComponent from '../CardComponent';
import { Card } from '../../types/card';
import { GameUpdate } from '../../types/gameUpdate';
import Tutorial from '../Tutorial';

interface HandProps {
  cards: Card[];
  boardCards: Card[];
  hoverCard: (hoveredCardPlayId: number) => void;
  placeCard: (cardPlayId: number, mode: 'attack' | 'defense') => void;
  placeSpell: (cardPlayId: number) => void;
  enableTargetMode: (cardPlayId: number | null) => void;
  targetMode: number | null;
  canPlace: boolean;
  actionPoints: number;
  gameState: GameUpdate;
}

interface HandState {
  selectedCard?: Card;
  hovered: boolean;
}

class Hand extends React.PureComponent<HandProps, HandState> {
  public readonly state: Readonly<HandState> = { hovered: false };

  private placeCard(cardPlayId: number, mode: 'attack' | 'defense'): void {
    this.props.placeCard(cardPlayId, mode);
    this.setState({ selectedCard: undefined });
  }

  private canPlaceEquipmentCard(equipment: Card): boolean {
    return !!this.props.boardCards.find(card => card.type === equipment.type && !card.isEquipment);
  }

  private placeSpell(cardPlayId: number): void {
    this.props.placeSpell(cardPlayId);
    this.setState({ selectedCard: undefined });
  }

  public render(): React.ReactNode {
    return (
      <Tutorial
        step="hand"
        gameState={this.props.gameState}
        padding={this.state.hovered ? 130 : undefined}
        additionalInfo={{
          selectedHandCard: this.state.selectedCard ? this.state.selectedCard.uniquePlayId : undefined,
        }}
      >
        <TransitionGroup
          className={classNames(styles.Wrapper, { [styles.CastPhase]: this.props.canPlace })}
          onMouseOver={(): void => this.setState({ hovered: true })}
          onFocus={(): void => this.setState({ hovered: true })}
          onMouseOut={(): void => this.setState({ hovered: false })}
          onBlur={(): void => this.setState({ hovered: false })}
        >
          <div className={styles.CardsWrapper}>
            {this.props.cards.map((card: Card) => (
              <CSSTransition
                key={card.uniquePlayId}
                timeout={300}
                classNames={{
                  enter: styles.CardEnter,
                  enterActive: styles.CardEnterActive,
                  exit: styles.CardExit,
                  exitActive: styles.CardExitActive,
                }}
              >
                <Tutorial
                  step="selectedHandCard"
                  gameState={this.props.gameState}
                  additionalInfo={{
                    handCard: card.uniquePlayId,
                    selectedHandCard: this.state.selectedCard ? this.state.selectedCard.uniquePlayId : undefined,
                  }}
                >
                  <div className={styles.CardWrapper}>
                    <CardComponent
                      cardId={card.cardId}
                      onClick={(): void => {
                        if (this.props.canPlace && this.props.actionPoints >= card.actionPoints) {
                          if (card.isEquipment && this.canPlaceEquipmentCard(card)) {
                            this.props.enableTargetMode(card.uniquePlayId);
                          } else if (card.isSpell && !card.needsTarget) {
                            this.setState({ selectedCard: card });
                          } else if (card.isSpell && card.needsTarget) {
                            this.props.enableTargetMode(card.uniquePlayId);
                          } else if (!card.isEquipment) {
                            this.setState({
                              selectedCard: card,
                            });

                            if (this.props.targetMode !== null) {
                              this.props.enableTargetMode(null);
                            }
                          }
                        }
                      }}
                      onMouseEnter={(): void => {
                        this.props.hoverCard(card.uniquePlayId);
                      }}
                      onMouseLeave={(): void => {
                        this.props.hoverCard(-1);
                      }}
                      className={classNames({
                        [styles.SilencedCard]:
                          !this.props.canPlace ||
                          (card.isEquipment && !this.canPlaceEquipmentCard(card)) ||
                          this.props.actionPoints < card.actionPoints,
                        [styles.SelectedCard]: this.props.canPlace && card.uniquePlayId === this.props.targetMode,
                      })}
                    />
                    {this.props.canPlace &&
                      this.state.selectedCard === card &&
                      card.isSpell &&
                      this.placeSpell(card.uniquePlayId)}
                    {this.props.canPlace && this.state.selectedCard === card && !card.isSpell && (
                      <div className={styles.SelectMode}>
                        <button type="button" onClick={(): void => this.placeCard(card.uniquePlayId, 'attack')}>
                          Attack Mode
                        </button>
                        <button type="button" onClick={(): void => this.placeCard(card.uniquePlayId, 'defense')}>
                          Defense Mode
                        </button>
                      </div>
                    )}
                  </div>
                </Tutorial>
              </CSSTransition>
            ))}
          </div>
        </TransitionGroup>
      </Tutorial>
    );
  }
}

export default Hand;
