import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

interface BoxProps {
  title?: string;
  size?: 'small';
  color?: 'primary' | 'green' | 'red';
  textColor?: string;
}

class Box extends React.PureComponent<BoxProps> {
  public render(): React.ReactNode {
    return (
      <div
        className={classNames(styles.Box, {
          [styles.Small]: this.props.size === 'small',
          [styles.Green]: this.props.color === 'green',
          [styles.Red]: this.props.color === 'red',
          [styles.Herbivore]: this.props.textColor === 'Herbivore',
          [styles.Carnivore]: this.props.textColor === 'Carnivore',
          [styles.Fish]: this.props.textColor === 'Fish',
        })}
      >
        {this.props.title && <div className={styles.Title}>{this.props.title}</div>}
        {this.props.children}
      </div>
    );
  }
}

export default Box;
