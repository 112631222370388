import React from 'react';
import styles from './styles.module.css';
import BattleBeastsLogo from '../../assets/logos/battlebeasts-logo-white.svg';

interface CardbackProps {
  cardUniqueId: number;
}

class CardbackComponent extends React.PureComponent<CardbackProps> {
  gameName = 'Battle Beasts';

  public render(): React.ReactNode {
    return (
      <div className={styles.Wrapper}>
        <div className={styles.OuterLayer}>
          <div className={styles.InnerLayer}>
            <img className={styles.CardImage} src={BattleBeastsLogo} alt={this.gameName} />
            <div className={styles.BottomWrapper}>
              <p>{this.gameName}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardbackComponent;
