import React, { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import styles from './styles.module.css';

class Button extends React.PureComponent<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> {
  public render(): React.ReactNode {
    return (
      <button className={styles.Button} {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;
