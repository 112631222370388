import React from 'react';
import styles from './styles.module.css';
import CardComponent from '../CardComponent';

interface UnlockedCardProps {
  rewardCardId: number;
}

class UnlockedCard extends React.PureComponent<UnlockedCardProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.Wrapper}>
        <p className={styles.Text}>Congratulations! You&apos;ve unlocked: </p>
        <CardComponent cardId={this.props.rewardCardId} />
      </div>
    );
  }
}

export default UnlockedCard;
