import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

class AfterGameOptions extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <div className={styles.Wrapper}>
        <Link className={styles.HomeBtnWrapper} to="/">
          Home
        </Link>
        <Link className={styles.DeckBtnWrapper} to="/deckpreview">
          Manage Deck
        </Link>
      </div>
    );
  }
}

export default AfterGameOptions;
