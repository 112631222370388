import React, { ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import Box from '../../components/Box';
import CardComponent from '../../components/CardComponent';
import { User } from '../../types/user';
import { Card } from '../../types/card';
import { Deck } from '../../types/deck';
import Button from '../../components/Button';
import { DeckService } from '../../services/deckService';
import { CardService } from '../../services/cardService';
import Guide from '../../components/Guide';

interface DecksProps extends RouteComponentProps<{ id: string }> {
  user: User;
}

interface DecksState {
  cards: { [key: string]: Card };
  decks: Deck[];

  selectedCardId?: number;
  selectedDeckName?: string;
  newNameForDeck?: string;
  createdDeck: Deck;

  error?: string;
  saved?: boolean;

  isClicked: boolean;
  showModal: boolean;
}

export class Decks extends React.PureComponent<DecksProps, DecksState> {
  public readonly state: Readonly<DecksState> = {
    cards: {},
    decks: [],
    selectedDeckName: '',
    newNameForDeck: '',
    createdDeck: {
      name: '',
      createdByUser: this.props.user.username,
      cards: [],
    },
    isClicked: false,
    showModal: false,
  };

  public async componentDidMount(): Promise<void> {
    const cardsRes = await CardService.getOwnedCards(this.props.user.token);
    this.setState({ cards: await cardsRes.json() });
    const userDecksRes = await DeckService.getDeck(`${this.props.user.token}`);
    const userDecksJson = await userDecksRes.json();
    if (userDecksJson.error) {
      this.setState({ error: userDecksJson.error });
    } else {
      this.setState({ decks: userDecksJson, error: undefined });
    }
    if (this.props.match.params.id === 'newDeck') {
      this.setState({ selectedDeckName: undefined });
    } else {
      this.setState({ selectedDeckName: this.props.match.params.id });
    }
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  private setNewDeckName(event: React.FormEvent<HTMLInputElement>): void {
    const newDeckName = event.currentTarget.value;
    this.setState(prevState => ({
      createdDeck: {
        ...prevState.createdDeck,
        name: newDeckName,
      },
    }));
  }

  private getAmountOfCardInDeck(deck: Deck, card: Card): number {
    let count = 0;
    deck.cards.forEach(cardId => {
      if (card.cardId === cardId) count += 1;
    });
    return count;
  }

  private setDeckName(event: React.FormEvent<HTMLInputElement>): void {
    const newDeckName = event.currentTarget.value;
    this.setState({ newNameForDeck: newDeckName });
  }

  private addCard(card: Card): void {
    this.setState(prevState => {
      const decks = [...prevState.decks];
      const createdDeck = { ...prevState.createdDeck };
      let cardNotValid = false;
      if (prevState.selectedDeckName) {
        decks.forEach((deck, index): void => {
          if (deck.name === prevState.selectedDeckName) {
            if (card.deckLimitation > this.getAmountOfCardInDeck(deck, card)) {
              decks[index].cards = [card.cardId, ...deck.cards];
            } else cardNotValid = true;
          }
        });
      } else if (card.deckLimitation > this.getAmountOfCardInDeck(createdDeck, card)) {
        createdDeck.cards = [card.cardId, ...prevState.createdDeck.cards];
      } else cardNotValid = true;
      if (cardNotValid) {
        if (card.deckLimitation === 1) {
          this.setState({ error: 'A deck can only contain one card of that type' });
        } else {
          this.setState({ error: `A deck can only contain ${card.deckLimitation} cards of that type` });
        }
        setTimeout(() => this.setState({ error: undefined }), 2000);
      }

      return {
        decks,
        createdDeck,
      };
    });
  }

  private removeCard(index: number): void {
    this.setState(prevState => {
      const decks = [...prevState.decks];
      const createdDeck = { ...prevState.createdDeck };
      if (prevState.selectedDeckName) {
        decks.forEach((deck, deckIndex) => {
          if (deck.name === prevState.selectedDeckName) {
            decks[deckIndex].cards = deck.cards.filter((_cardId, cardIndex) => cardIndex !== index);
          }
        });
      } else {
        createdDeck.cards = createdDeck.cards.filter((_cardId, cardIndex) => cardIndex !== index);
      }

      return { decks, createdDeck };
    });
  }

  private selectCard(cardId: number): void {
    this.setState({ selectedCardId: cardId });
  }

  private unselectCard(cardId: number): void {
    if (this.state.selectedCardId === cardId) {
      this.setState({ selectedCardId: undefined });
    }
  }

  public handleOpenModal(numberKey: number): void {
    this.setState({ selectedCardId: numberKey });
    this.setState({ showModal: true });
  }

  public handleCloseModal(): void {
    this.setState({ showModal: false });
  }

  private async saveDeck(): Promise<void> {
    if (this.state.selectedDeckName) {
      const selectedDeck = this.state.decks.find(deck => deck.name === this.state.selectedDeckName);
      if (!selectedDeck) {
        this.setState({ error: "This deck doesn't seem to exist?" });
        return;
      }

      const saveDeckRes = this.state.newNameForDeck
        ? await DeckService.patchDeckRename(
            `${this.props.user.token}`,
            this.state.selectedDeckName,
            this.state.newNameForDeck,
            selectedDeck.cards,
          )
        : await DeckService.patchDeck(`${this.props.user.token}`, this.state.selectedDeckName, selectedDeck.cards);

      const saveDeckResJson = await saveDeckRes.json();
      if (saveDeckResJson.error) {
        this.setState({ error: saveDeckResJson.error, saved: false });
      } else {
        this.setState({ error: undefined, saved: true });
        setTimeout(() => this.setState({ saved: false }), 2000);
      }
    } else {
      const saveDeckRes = await DeckService.postDeck(
        `${this.props.user.token}`,
        this.state.createdDeck.name,
        this.state.createdDeck.cards,
      );
      const saveDeckResJson = await saveDeckRes.json();
      if (saveDeckResJson.error) {
        this.setState({ error: saveDeckResJson.error, saved: false });
      } else {
        this.setState(prevState => ({
          decks: [...prevState.decks, prevState.createdDeck],
          createdDeck: {
            name: '',
            createdByUser: this.props.user.username,
            cards: [],
          },
          selectedDeckName: prevState.createdDeck.name,
          saved: true,
          error: undefined,
        }));
        setTimeout(() => this.setState({ saved: false }), 2000);
      }
    }
  }

  private disableSaveButton(
    createdDeckName: string,
    createdDeckCards: Array<number>,
    selectedDeckName: string | undefined,
    decks: Array<Deck>,
  ): boolean {
    let selectedDeckCards: Array<number> = [];
    decks.forEach(
      deck => (deck.name === selectedDeckName ? (selectedDeckCards = deck.cards) : undefined), // eslint-disable-line no-return-assign
    );
    return !!(
      (createdDeckName === '' && selectedDeckName === undefined) ||
      (createdDeckName === '' && createdDeckCards.length === 0 && selectedDeckCards.length !== 20) ||
      (createdDeckName && createdDeckCards.length !== 20 && selectedDeckName === undefined)
    );
  }

  private screenScaling(key: string, numberKey: number): object {
    const media = window.matchMedia('(max-width: 650px)');
    if (media.matches) {
      return (
        <div className={styles.MainPart}>
          <div key={key} onClick={this.handleOpenModal.bind(this, numberKey)}>
            <CardComponent cardId={this.state.cards[numberKey].cardId} hideDescription />
          </div>
        </div>
      );
    }
    return (
      <div
        key={key}
        onMouseEnter={(): void => this.selectCard(numberKey)}
        onMouseLeave={(): void => this.unselectCard(numberKey)}
        onClick={(): void => this.addCard(this.state.cards[numberKey])}
      >
        <CardComponent cardId={this.state.cards[numberKey].cardId} hideDescription />
      </div>
    );
  }

  private sortCards(cards: any[]): any[] {
    return cards.sort((key1, key2) => {
      if (this.state.cards[key1].type === this.state.cards[key2].type) {
        if (this.state.cards[key1].isEquipment === this.state.cards[key2].isEquipment) {
          return this.state.cards[key1].name < this.state.cards[key2].name ? -1 : 1;
        }
        return this.state.cards[key1].isEquipment ? 1 : -1;
      }
      return this.state.cards[key1].type < this.state.cards[key2].type ? -1 : 1;
    });
  }

  public addCardFromModal(): void {
    if (this.state.selectedCardId !== undefined) {
      this.addCard(this.state.cards[parseInt(this.state.selectedCardId.toString(), 10)]);
    }
    this.handleCloseModal();
  }

  private isDeckOwnedByPlayer(deckName: string): boolean {
    if (this.state.decks.length) {
      return this.state.decks.map(deck => deck.name).includes(deckName);
    }

    return true;
  }

  private renderSelectedCard(cardId: number, index: number): ReactElement {
    const card = this.state.cards[cardId];

    return (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        onMouseEnter={(): void => this.selectCard(card.cardId)}
        onMouseLeave={(): void => this.unselectCard(card.cardId)}
        onClick={(): void => this.removeCard(index)}
      >
        <CardComponent cardId={card.cardId} hideDescription />
      </div>
    );
  }

  public render(): React.ReactNode {
    let showSelectedDeck;

    if (this.state.selectedDeckName === undefined) {
      showSelectedDeck = (
        <form className={styles.Inputform}>
          <Guide instructions="nameDeck" position="bottom" decks={this.state.decks} disable={this.state.isClicked}>
            <input
              onClick={(): void => this.setState({ isClicked: true })}
              className={styles.InputDeckname}
              onChange={this.setNewDeckName.bind(this)}
              type="text"
              placeholder="Deck name..."
            />
          </Guide>
          <label className={styles.DeckNameLabel}>Deck name</label>
        </form>
      );
    } else if (this.isDeckOwnedByPlayer(this.props.match.params.id)) {
      showSelectedDeck = (
        <h3>
          <span>Your deck name: </span>
          <input
            onClick={(): void => this.setState({ isClicked: true })}
            className={styles.InputDeckname}
            onChange={this.setDeckName.bind(this)}
            type="text"
            placeholder="Deck name..."
            contentEditable
            defaultValue={this.state.selectedDeckName}
          />
        </h3>
      );
    } else {
      showSelectedDeck = (
        <>
          <h3>
            <span>The deck: </span>
            {this.state.selectedDeckName}
            <br />
            <span>doesn&apos;t seem to exist or belong to you</span>
          </h3>
          <Link to="/deckpreview">
            <Button type="button">Go back to your decks</Button>
          </Link>
        </>
      );
    }
    return (
      <div>
        <ReactModal
          className={styles.Modal}
          overlayClassName={styles.Overlay}
          ariaHideApp={false}
          isOpen={this.state.showModal}
        >
          <div className={styles.CardMini}>
            {this.state.selectedCardId !== undefined ? <CardComponent cardId={this.state.selectedCardId} /> : null}
          </div>
          <div className={styles.DescriptionForMobile}>
            {this.state.selectedCardId !== undefined ? this.state.cards[this.state.selectedCardId].description : null}
          </div>
          <button className={styles.btn} onClick={this.handleCloseModal.bind(this)}>
            Close
          </button>
          <button className={styles.btn} onClick={(): void => this.addCardFromModal()}>
            Add to deck
          </button>
        </ReactModal>
        <div className={styles.Wrapper}>
          <div className={styles.AvailableCardsBoxWrapper} data-cy="available-cards">
            <Guide
              instructions="addCard"
              position="bottom"
              decks={this.state.decks}
              disable={!(this.state.createdDeck.cards.length < 1)}
            >
              <Box>
                <h2>Available</h2>
                <div className={styles.AvailableCardsList}>
                  {this.sortCards(Object.keys(this.state.cards)).map(key => {
                    const numberKey = parseInt(key, 10);
                    return this.screenScaling(key, numberKey);
                  })}
                </div>
              </Box>
            </Guide>
          </div>
          <div className={styles.CenterWrapper} data-cy="center">
            <div className={styles.DeckSelector}>{showSelectedDeck}</div>
            <div>
              {this.state.selectedCardId !== undefined ? <CardComponent cardId={this.state.selectedCardId} /> : null}
            </div>
            <div>{this.state.error ? <Box>An Error occured: {this.state.error}</Box> : null}</div>
            <div>{this.state.saved ? <Box>Deck successfully saved</Box> : null}</div>
          </div>
          <div className={styles.SelectedCardsBoxWrapper} data-cy="selected-cards">
            <Guide
              instructions="completeDeck"
              position="left"
              decks={this.state.decks}
              disable={this.state.createdDeck.cards.length !== 1}
            >
              <Box>
                <h2>
                  Deck cards (
                  {this.state.selectedDeckName
                    ? (
                        this.state.decks.find(deck => deck.name === this.state.selectedDeckName) || {
                          cards: [] as number[],
                        }
                      ).cards.length
                    : this.state.createdDeck.cards.length}
                  /20)
                </h2>
                <div className={styles.SelectedCardsList}>
                  {this.state.selectedDeckName
                    ? this.sortCards(
                        (
                          this.state.decks.find(deck => deck.name === this.state.selectedDeckName) || {
                            cards: [] as number[],
                          }
                        ).cards,
                      ).map((cardId, index) => this.renderSelectedCard(cardId, index))
                    : this.sortCards(this.state.createdDeck.cards).map((cardId, index) =>
                        this.renderSelectedCard(cardId, index),
                      )}
                </div>
                <div className={styles.MobileFeedback}>
                  {this.state.error && `An Error occured: ${this.state.error}`}
                  {this.state.saved && `Deck successfully saved`}
                </div>
                <Guide
                  instructions="saveDeck"
                  position="left"
                  decks={this.state.decks}
                  disable={this.state.createdDeck.cards.length !== 20}
                >
                  <Link to={`/decks/${this.state.createdDeck.name}`}>
                    <Button
                      onClick={this.saveDeck.bind(this)}
                      disabled={this.disableSaveButton(
                        this.state.createdDeck.name,
                        this.state.createdDeck.cards,
                        this.state.selectedDeckName,
                        this.state.decks,
                      )}
                    >
                      Save
                    </Button>
                  </Link>
                </Guide>
              </Box>
            </Guide>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Decks);
