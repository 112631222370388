import React from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import BattleBeastsLogo from '../../assets/logos/battlebeasts-logo-white.svg';
import Container from '../Container';
import NavigationItem from '../NavigationItem';
import LoginDropdown from '../LoginDropdown';
import Hamburger from '../Hamburger';
import { User } from '../../types/user';
import Button from '../Button';

interface NavigationProps {
  onLogin: (token: string) => void;
  onLogout: () => void;
  isLoggedIn: boolean;
  userNav?: User;
}

interface HamburgerState {
  isOpen: boolean;
  isMobile: boolean;
  color: string;
}
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
export class Navigation extends React.PureComponent<NavigationProps & RouteComponentProps, HamburgerState> {
  private isTransitionAllowed = true;

  constructor(props: Readonly<NavigationProps & RouteComponentProps>) {
    super(props);
    this.state = {
      isOpen: false,
      isMobile: false,
      color: 'white',
    };
  }

  componentDidMount(): void {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (this.state.isMobile && !prevState.isMobile && this.props.isLoggedIn) {
      this.isTransitionAllowed = false;
      setTimeout(() => {
        this.isTransitionAllowed = true;
      }, 200);
    } else {
      this.isTransitionAllowed = true;
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  toggleHamburger = (): void => {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen };
    });
  };

  handleWindowResize = (): void => {
    this.setState({
      isMobile: window.innerWidth < 650,
    });
    if (this.state.isMobile) {
      this.setState(prevState => {
        return { isOpen: this.props.isLoggedIn ? false : prevState.isOpen };
      });
    } else {
      this.setState({ isOpen: true });
    }
  };

  closeHamburgerWithinUL = (e: any): void => {
    if (
      this.state.isMobile &&
      !(e.target.tagName === 'SPAN') &&
      !(e.target.tagName === 'DIV') &&
      !(e.target.innerText === 'Login') &&
      !(e.target.nodeName === 'FORM') &&
      !(e.target.name === 'username') &&
      !(e.target.name === 'password') &&
      !(e.target.nodeName === 'BUTTON') &&
      !(e.target.nodeName === 'UL') &&
      !(e.target.innerText === 'Required')
    ) {
      this.setState({ isOpen: false });
    } else {
      this.setState(prevState => {
        return { isOpen: prevState.isOpen };
      });
    }
  };

  closeHamburger = (): void => {
    if (this.state.isMobile) {
      this.setState({ isOpen: false });
    } else {
      this.setState(prevState => {
        return { isOpen: prevState.isOpen };
      });
    }
  };

  public render(): React.ReactNode {
    return (
      <nav
        className={classNames(styles.Navigation, { [styles.Hidden]: this.props.location.pathname.startsWith('/play') })}
      >
        <Container>
          <Link to="/" className={styles.Brand} onClick={this.closeHamburger}>
            <img className={styles.Logo} src={BattleBeastsLogo} alt="Battle Beasts" />
            <span className={styles.Title}>BattleBeasts</span>
          </Link>
          <div className={this.state.isMobile ? styles.HamburgerWrapper : ''}>
            {this.state.isMobile ? (
              <Hamburger open={this.state.isOpen} onClick={this.toggleHamburger} color={this.state.color} />
            ) : (
              <></>
            )}
          </div>
          <ul
            onClick={this.closeHamburgerWithinUL}
            className={classNames(styles.NavItems, {
              [styles.HamburgerNavItems]: this.state.isMobile,
              [styles.HamburgerFade]: !this.state.isOpen,
              [styles.HamburgerClose]: !this.isTransitionAllowed,
            })}
          >
            <NavigationItem key="leaderboard" href="/leaderboard">
              Leaderboard
            </NavigationItem>
            <NavigationItem key="about" href="/about">
              About the game
            </NavigationItem>
            {this.props.isLoggedIn ? (
              <>
                <NavigationItem key="decks" href="/deckpreview">
                  My Decks
                </NavigationItem>
                <NavigationItem
                  key="logout"
                  dropdownContent={
                    <div className={styles.ButtonWrapper}>
                      <Button
                        type="submit"
                        onClick={(event): void => {
                          event.preventDefault();
                          this.props.onLogout();
                        }}
                      >
                        Logout
                      </Button>
                    </div>
                  }
                >
                  {this.props.userNav ? this.props.userNav.username : null}
                </NavigationItem>
              </>
            ) : (
              <>
                <NavigationItem key="register" href="/register">
                  Register
                </NavigationItem>
                <NavigationItem key="login" dropdownContent={<LoginDropdown onLogin={this.props.onLogin} />}>
                  Login
                </NavigationItem>
              </>
            )}
          </ul>
        </Container>
      </nav>
    );
  }
}

export default withRouter(Navigation);
