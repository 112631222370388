import React from 'react';
import styles from './styles.module.css';

class Container extends React.PureComponent {
  public render(): React.ReactNode {
    return <div className={styles.Container}>{this.props.children}</div>;
  }
}

export default Container;
